import React, { PureComponent } from 'react';
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import PaymentForm from './PaymentForm'

const PUBIC_KEY = 'pk_test_51IomU4AhPg2NSKk0ys7LYRBPrSfthvB7F7qVrIaUgvf4cPa8GxgJT8oqRBCrhwBLP9eFcwdo1ZB7xhLG4F8JpANv007mNnB8gC'
const stripeTestPromise = loadStripe(PUBIC_KEY)


const StripeContainer = (props) => (
  <div className="account">
    <div className="account__wrapper" style={{ width: '50%' }}>
      <div className="account__card" style={{ margin: 'auto', maxWidth: "650px" }} >
        <Elements stripe={stripeTestPromise}>
          <PaymentForm info={props} />
        </Elements>
      </div>
    </div>
  </div>
);

export default StripeContainer;

