import React, { useEffect, useState } from 'react';
import { Container, Card, CardBody, Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import TrendingUpIcon from 'mdi-react/TrendingUpIcon';
import fetchMethodRequest from '../../../config/service';
import apiCalls from '../../../config/apiCalls';

const CountDisplay = (props) => {
	let loginRole = localStorage.getItem("loginCredentials");
	loginRole = JSON.parse(loginRole);
	const [count, setCount] = useState({
		facilitiesCount: 0,
		locationsCount: 0,
		productsCount: 0,
		companiesCount: 0,
		employeesCount: 0,
		facilityStaffsCount: 0
	});
	const [itemsList] = useState([
		{ displayName: 'Facilities', route: '/facilities', color: '#08699f', key: 'facilitiesCount' },
		{ displayName: 'Storage Unit', route: '/storageUnits', color: '#d65356', key: 'storageUnitCount' },
		{ displayName: 'Facility Staff', route: '/facilityStaffs', color: '#23B7D9', key: 'facilityStaffsCount' },
		{ displayName: 'Products', route: '/products', color: '#F29B9B', key: 'productsCount' }
	]);
	const [superAdminItemsList] = useState([
		{ displayName: 'Facilities', route: '/facilitiess', color: '#08699f', key: 'facilitiesCount' },
		{ displayName: 'Storage Unit', route: '/storageUnitss', color: '#d65356', key: 'storageUnitCount' },
		{ displayName: 'Companies', route: '/companyUsers', color: '#23B7D9', key: 'companiesCount' },
		{ displayName: 'Employees', route: '/employees', color: '#F29B9B', key: 'employeesCount' }
	]);

	useEffect(() => {
		dashBoardCardsCount();
	}, []);

	/**fetch the dashboard display count */
	const dashBoardCardsCount = async () => {
		try {
			const response = await fetchMethodRequest('GET', apiCalls.dashboard);
			if (response) {
				setCount(response.details);
			}
		} catch (error) {
			console.error('Error fetching data:', error);
		}
	}

	return (
		<Container>
			<Row>
				{(loginRole.role === 'Super Admin' ? superAdminItemsList : itemsList).map((item, index) => (
					<Col md={12} xl={3} lg={6} xs={12} key={index}>
						<Link to={item.route === '/products' ? `${item.route}/redirect` : item.route === '/employees' ? `${item.route}/redirect` : item.route} style={{ textDecoration: 'none' }}>
							<Card>
								<CardBody className="dashboard__card-widget" style={{ backgroundColor: item.color }}>
									<div className="card__title">
										<h5 className="bold-text text-white">{item.displayName}</h5>
									</div>
									<div className="dashboard__total text-white">
										<TrendingUpIcon className='text-white' />
										<p className="dashboard__total-stat text-white">
											{count[item.key]}
										</p>
									</div>
								</CardBody>
							</Card>
						</Link>
					</Col>
				))}
			</Row>
		</Container >
	);
}

export default CountDisplay;
