import React, { useState } from 'react';
import { Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';

const RecallTabType = (props) => {
    const [activeTab, setActiveTab] = useState(0);
    const [handleRecallTypes, setHandleRecallTyprs] = useState([
        { name: 'Recall a Item', value: 'Recall a Item' },
        { name: 'Items Recalled', value: 'Items Recalled' },
    ]);
    const [currentShift, setCurrentShift] = useState(null);

    const handleRecallTypesFunc = (tab, RecallTabType) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
            setCurrentShift(RecallTabType);
        }
        props.getSelectedRecallTypeInfo(RecallTabType);
    };

    return (
        <div>
            <div className="tabs tabs--justify tabs--bordered-top">
                <div className="tabs__wrap">
                    <Nav tabs>
                        {handleRecallTypes && handleRecallTypes.length > 0 ? (
                            handleRecallTypes.map((RecallTabType, index) => (
                                < NavItem key={index} style={{ width: '50%', textAlign: "center" }}>
                                    <NavLink
                                        className={classnames({ active: activeTab === index })}
                                        onClick={() => {
                                            handleRecallTypesFunc(index, RecallTabType.name);
                                        }}
                                    >
                                        <span style={{ fontSize: 15 }}>{RecallTabType.value}</span>
                                    </NavLink>
                                </NavItem>
                            ))
                        ) : (
                            null
                        )}
                    </Nav>
                </div>
            </div>
        </div >
    );
};

export default RecallTabType;
