import React from 'react';
import { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';


// config file
const PaymntConfirmationModal = (props) => {
    const [state, setState] = useState({});

    
   const deleteSelectedRow = () => {
        // this.props.deleteSelectedRow();
    }

    return (
        <div>
                <Modal isOpen={props.openConfirmationModal} className='deleteModalHeight confirm_modal'>
                    <ModalBody className='deleteModalBody'>
                        <div style={{ marginBottom: 10 }}>{props.text}?</div>
                        <Button color="primary" outline onClick={props.closeConfirmationModal} className='deleteModalBtn marginRight'>{props.isNo}</Button>
                        <Button color="primary" outline onClick={props.confirm}
                            className='deleteModalBtn'>{props.isYes}</Button>
                    </ModalBody>
                </Modal>
            </div>
    );
};

export default PaymntConfirmationModal;