import React from 'react';
import { useState , useEffect} from 'react';
import {
    Nav, NavItem, NavLink
} from 'reactstrap';

import classnames from 'classnames';

const CommonTabTypes = (props) =>{

    const [activeTab, setActiveTab] = useState(0);
    const [currentShift, setCurrentShift] = useState(null);
    const [handleRecallTypes , setHandleRecallTypes] = useState([
        { name: 'Cart Location', value: 'Cart Location' },
        { name: 'Supply Cabinets', value: 'Supply Cabinets' },
        { name: 'OR Suites', value: 'OR Suites' },
    ]);

    useEffect(() => {
        props.getSelectedStorageUnit("Cart Location");
    }, []);

    //  Handle Selected Group
     const handleRecallType = (tab, RecallTabType) => {
        setActiveTab(tab && tab)
        if (activeTab !== tab) {
            setActiveTab(tab);
            props.getSelectedStorageUnit(RecallTabType);
        }
        
    };

    return (
        <div>
            <div className="tabs tabs--justify tabs--bordered-top">
                <div className="tabs__wrap">
                    <Nav tabs>
                        {handleRecallTypes && handleRecallTypes.length > 0 ?
                            handleRecallTypes.map((RecallTabType, index) => {
                                // if(props.allUserData[RecallTabType.value]){
                                    return (
                                        <NavItem key={index} style = {{width:'33.3%' , textAlign:"center"}}>
                                            <NavLink
                                                className={classnames({ active: activeTab === index })}
                                                onClick={() => {
                                                    handleRecallType(index, RecallTabType.value);
                                                }}
                                                >
                                                    <span style={{ fontSize: 15}}>{RecallTabType.name}</span></NavLink>
                                        </NavItem>
                                    )
                                // }
                               
                            })
                            : null}
                    </Nav>
                </div>
            </div>

        </div>
    );
    
}
export default CommonTabTypes