import React, { useState, useEffect, useRef } from 'react';
import {
  Modal, ModalHeader, ModalBody,
  Card, CardBody, ButtonToolbar, ButtonGroup, Row, Col, Badge
} from 'reactstrap';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import classNames from 'classnames';
import Moment from 'moment';
import moment from 'moment'
import { load as loadAccount } from './../../redux/reducers/commonReducer';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes, { element } from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import PaginatorComponent from '../Cruds/CommonDataTable/PaginatorComponent';
import OrderTabTypes from '../Cruds/TabTypes/OredrTabTypes';
//components 
import AutoComplete from './components/AutoComplete';
import CalendarBlankIcon from 'mdi-react/CalendarBlankIcon';
import Select from '../../shared/components/form/Select';
import RadioButton from '../../shared/components/form/RadioButton';
import DefaultInput from '../../shared/components/form/DefaultInput';
import DefaultTextArea from '../../shared/components/form/DefaultTextArea';
import DatePicker from '../../shared/components/form/DatePicker';
import TimePicker from '../../shared/components/form/TimePicker';
import renderRadioButtonField from '../../shared/components/form/RadioButton';
import UserPasswordResetModal from '../Cruds/CommonModals/UserPasswordResetModal';
import { Dropdown } from 'primereact/dropdown';
import RenderFileInputField from '../Form/components/FileUpload';
// import {CKEditor} from 'ckeditor4-react';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import TextEditorTwo from '../../shared/components/TextEditor';

import config from '../../config/config';
import configMessages from '../../config/configMessages';
import apiCalls from '../../config/apiCalls';
import fetchMethodRequest from '../../config/service';
import DataTables from '../Cruds/CommonDataTable/DataTable';
// Toaster message
import showToasterMessage from '../UI/ToasterMessage/toasterMessage';
import EyeIcon from 'mdi-react/EyeIcon';
import dateFormats from '../UI/FormatDate/formatDate';
import validate from '../Validations/validate';

// Multi select Dropdown
import MultiSelectDropDown from './components/MultiSelect';

// Loader
import Loader from '../App/Loader';
// Calendar
//session expiry modal
import DeleteRowModal from '../Cruds/CommonModals/DeleteRowModal';
import SessionExpiryModal from '../Cruds/CommonModals/SessionexpiryModal'
//import TicketCommentsInfo from '../Tables/PrimeReactTable/Tickets/components/TicketCommentsInfo';
import { th } from 'date-fns/locale';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Scrollbar from 'smooth-scrollbar-react';
import { useForm, Controller } from 'react-hook-form';
import { Button } from 'primereact/button';
import DoInputField from "./Fields/DoInputField";
import DoPasswordField from "./Fields/DoPasswordField";
import DoDateField from './Fields/DoDateField';
import DoSelectField from './Fields/DoSelectField';
import DoTextareaField from './Fields/DoTextareaField';
import DoMultiSelectField from './Fields/DoMultiSelectField';
import DoRadioButtons from './Fields/DoRadioButtons';
import DoCheckboxField from './Fields/DoCheckboxField';
import DoAutoCompleteField from './Fields/DoAutoCompleteField';
import DoEditor from './Fields/DoEditor';
import { getPasswordRegex } from './DoValidations';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import DoFileUpload from './Fields/DoFileUpload';
import OptionsModal from '../Cruds/CommonModals/OptionsModal';
import { Dialog } from 'primereact/dialog';



let radioRequired = value => {
  let error = undefined;
  if (value || typeof value === 'string') {
    error = undefined
  } else {
    error = configMessages.fillRadio
  }
  return error
}
let required = value => (value || typeof value === 'string' ? undefined : configMessages.fillField);
let normalizePhone = (value) => {
  if (!value) {
    return value
  }
  let onlyNums = value.replace(/[^\d]/g, '')
  if (onlyNums.length <= 3) {
    return onlyNums
  }
  if (onlyNums.length <= 7) {
    return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3)}`
  }
  return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3, 6)}-${onlyNums.slice(6, 10)}`
}


let FormModal = (props) => {
  let [display, setDisplay] = useState(false);
  let [displayBreadCrumbValue, setDisplayBreadCrumbValue] = useState(props.displayBreadCrumbValue);
  let [displayBreadCrumbField, setDisplayBreadCrumbField] = useState(props.displayBreadCrumbField);
  let [userStatus, setUserStatus] = useState(props.userStatus);
  let [formFields, setFormFields] = useState([]);
  let [formValues, setFormValues] = useState({});
  let [colored, setColored] = useState(false);
  let [header, setHeader] = useState(true);
  let [isLoading, setIsLoading] = useState(false);
  let [defaultValue, setDefaultValue] = useState(true);
  let [roleOptions, setRoleOptions] = useState([]);
  let [slno, setSslno] = useState(0);
  let [rowData, setRowData] = useState('');
  let [originalTableFields, setOriginalTableFields] = useState(props.originalTableFields);
  let [actions, setActions] = useState('');
  let [confirmType, setConfirmType] = useState('');
  let [confirmModalText, setConfirmModalText] = useState('');
  let [activitiesData, setActivitiesData] = useState([]);
  let [totalRecordsLength, setTotalRecordsLength] = useState(0);
  let [first, setFirst] = useState(0);
  let [rows, setRows] = useState(10);
  let [page, setPage] = useState(1)
  let [showorHideLevelsField, setShoworHideLevelsField] = useState(false);
  let [filterCriteria, setFilterCriteria] = useState({ limit: 10, page: 1, criteria: [], sortfield: 'created', direction: 'desc' });
  let [editRowDataID, setEditRowDataID] = useState();
  let [formType, setFormType] = useState(props.formType);
  let [userData, setUserData] = useState();
  let [menuList, setMenuList] = useState(props.menuList ? props.menuList : []);
  let [selectActions, setSelectActions] = useState();
  let [selectedRows, setSelectedRows] = useState();
  let [sortCount, setSortCount] = useState();

  let [sessionExpiryModal, setSessionExpiryModal] = useState(false);
  let [openUserPasswordResetModal, setOpenUserPasswordResetModal] = useState(false);
  let [openOptionModal, setOpenOptionModal] = useState(false)
  let [openDeleteModal, setOpenDeleteModal] = useState(false);
  let [isProductName, setIsProductName] = useState(undefined)
  const [storageUnitId, setstorageUnitId] = useState(props.storageUnitId);
  const [Invlocations, setInvLocations] = useState([]);
  const [inventoryLocationId, setInventoryLocationId] = useState('');
  const [selectedRowData, setSelectedRowData] = useState(props.tableRowData);
  const [invStates, setInvStates] = useState(undefined);
  const [minInventoryByStateId, setMinInventoryByStateId] = useState('');
  let [schema, setSchema] = useState('');
  const [optionsValue, setOptionsValue] = useState();
  const [elementName, setElementName] = useState();
  const [checkBoxChecked, setCheckBoxChecked] = useState(false);
  const [rowInform, setRowInform] = useState(false)
  const [showVolumeFields, setShowVolumeField] = useState(false)
  const [selectedData, setSelectedData] = useState()
  const [inputValue, setInputValue] = useState();
  const [drawerField, setDrawerField] = useState();
  const [dropDownOptions, setDropdownOptions] = useState();
  // const [values, setValue] = useState()




  useEffect(() => {
    /**@Changing indexValue of the modal */

    const modal = document.querySelector('div[tabindex="-1"][style*="z-index: 1050;"]');
    if (modal) {
      modal.style.zIndex = '300';
    }

    const formData = props.formFields(props.tabType ? props.tabType : null);
    const requiredFields = [];

    let tschema = setValidations(formData);
    setSchema(tschema)
    let sessionexpired = localStorage.getItem('sessionexpired');
    if (sessionexpired) {
      setSessionExpiryModal(true);
    }
    getActivities();
    let timeOut = props.addSelectedRecord ? 0 : 800;
    // setTimeout(() => {
    getFormFields();
    // }, timeOut);
    let recordId = props.selectedId ? props.selectedId : props.tableRowData._id;
    if (props.formType === 'edit' && props.type === 'Storage Units') {
      // handleApiCall();
    }
    if (props.formType === 'view') {
      handleViewDisplay(getSelectedData(props.selectedId, props.allUsersData), 'view')
    }
    else if (props.formType === 'edit' && recordId) {
      getRowData(getSelectedData(recordId, props.allUsersData), 'edit');
    }
    // handleCategoryNames();
    loadDetailsToOrderList();
    setTimeout(() => {
      let selectedData = props.formType !== 'add' ? getSelectedData(recordId, props.allUsersData) : {};
      setSelectedData(selectedData)
      if (props.formType !== 'view' && props.formFields) {
        props.formFields().map((obj) => {
          if (obj.value && obj.name && !selectedData[obj.name]) {
            setValue(obj.name, obj.value)
          }
        })
      }
    }, 1000);

    setTimeout(() => setDisplay(true), 100)

  }, []);

  useEffect(() => {
    if (props.type === "Employee Roles") {
      const setInitialValues = () => {
        setValue('permission', {});
        // setValue('levels', 1);
        const permissionsData = generatePermissionsData();
        if (permissionsData && permissionsData.length > 0) {
          permissionsData.forEach(screen => {
            if (screen && screen.name) {
              setValue(screen.name, screen.value);
            }
          });
        }
      };
      setInitialValues();
    }
  }, [props.selectedId, props.allUsersData]);

  useEffect(() => {
    // debugger
    let fields = formFields;
    let fieldIndex = fields.findIndex(item => item.name === 'sublocation');
    if (fieldIndex !== -1 && dropDownOptions) {
      let requiredField = { ...fields[fieldIndex] }; // Create a copy of the found field to modify it
      requiredField.show = true;
      requiredField.options = dropDownOptions;
      if (props.formType === 'edit') {
        requiredField.disabled = true;
        requiredField.isEditFieldDisable = true;
      }
      fields[fieldIndex] = requiredField; // Replace the old field with the updated one
    }

    setFormFields(fields)
  }, [dropDownOptions, formFields])

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (drawerField == "noofDrawers" && inputValue) {
        // handleApiCall();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [inputValue, drawerField])


  const handleApiCall = async () => {
    const storageTypes = { "Cart Location": 'cartLocation', "Supply Cabinets": 'supplyCabinet', "OR Suites": 'orSuit' };
    let editdata;
    let edittfieldData;
    if (props.formType === 'edit') {
      let recordId = props.selectedId ? props.selectedId : props.tableRowData._id;
      editdata = getSelectedData(recordId, props.allUsersData);
      edittfieldData = editdata.noofDrawers
      setInputValue(editdata.noofDrawers)
      console.log("props.allUsersData", edittfieldData, inputValue)
    }
    const normalizedValue = inputValue ? parseInt(inputValue) : null;
    const values = {
      noofDrawers: props.formType === 'edit' && edittfieldData ? edittfieldData : normalizedValue, // Send the numeric value for the API call
    };

    try {
      if (props.tabType in storageTypes) {
        const response = await fetchMethodRequest('POST', `${apiCalls.drawers}?storageUnitType=${storageTypes[props.tabType]}`, values);

        if (response && response.respCode) {
          // Handle success
          console.log('API success:', response.respMessage);

          // Transform the storageUnitSubLocations into an array of objects with label and value
          const cartSubLocations = response.storageUnitSubLocations.map((item) => ({
            label: item,
            value: item
          }));

          // Set the dropdown options to the transformed array
          setDropdownOptions(cartSubLocations);

        }
      } else if (response && response.errorMessage) {
        // Handle error
        console.log('API error:', response.errorMessage);
      }
    } catch (error) {
      console.error("API Error:", error);
    }
  };



  // useEffect(() => {
  //   if (optionsValue) {
  //     setCheckBoxChecked(false);
  //   }
  // }, [optionsValue]);

  // useEffect to set checkbox state based on selectedRowInfo
  // useEffect(() => {
  //   if (rowInform && !rowInform.recommendedInventoryName) {
  //     setCheckBoxChecked(true);
  //   }
  // }, [rowInform]);



  let {
    handleSubmit,
    register,
    reset,
    setValue,
    getValues,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(schema),
  });



  // Add To Order List 

  const loadDetailsToOrderList = () => {
    if (props.screen === "wishlist" && props.tableRowData) {
      let tableRowData = props.tableRowData
      setValue("productName", tableRowData.productName);
      setValue("description", tableRowData.description);
      setValue("volume", tableRowData.volume);
      setValue("needToOrder", tableRowData.needToOrder);
    }
  }



  //state Options
  const handleCategoryNames = async (e) => {
    // this.setState({
    //   selectedInventoryType: "recommended"
    // })
    let filterCriteria = { sortfield: "name", direction: "desc" }
    let loggedUser = JSON.parse(localStorage.getItem('loginCredentials'));
    let companyId = loggedUser._id

    filterCriteria.criteria = [{ key: "companyId", value: companyId, type: "eq" }]
    return fetchMethodRequest("GET", `minInventoryByStates`).then(
      async (response) => {
        let invStates = [];
        if (response && response.minInventoryByStatesResp) {
          for (let state of response.minInventoryByStatesResp) {
            invStates.push({
              label: state.minInventoryByStateName,
              value: state.minInventoryByStateName,
              state: state
            });
          }
        }
        await setInvStates(invStates);
      }
    );
  }

  const getSelectedData = (id, allData) => {
    allData = allData ? allData : []

    if (id && id.startsWith('"') && id.endsWith('"')) {
      id = JSON.parse(id);
    }
    const data = allData.filter((row) => row._id === id);
    return (data.length > 0) ? data[0] : [];
  }

  // const getSelectedData = (id, allData) => {
  //   if (typeof id === 'string') {
  //     id = id.trim();
  //     if (id.startsWith('"') && id.endsWith('"')) {
  //       try {
  //         id = JSON.parse(id);
  //       } catch (error) {
  //         return [];
  //       }
  //     }
  //   } else {
  //     return [];
  //   }

  //   const data = allData.filter((row) => row._id === id);
  //   return (data.length > 0) ? data[0] : [];
  // };



  let setValidations = (fields) => {
    let valObj = {};
    fields.forEach((field) => {
      console.log("field", field)
      if (((!field.isAddFormHidden && props.formType === 'add') || (!field.isEditFormHidden && props.formType === 'edit')) && field.required && field.show) {
        // if (!field.isAddFormHidden && props.formType === 'add' && field.required) {
        valObj[field.name] = getValidations(field);
      }
    });
    return yup.object().shape(valObj)
  }

  let getValidations = (field) => {
    if (field.type === 'number') {
      if (field.name === "numberOfItemsRecieved") {
        const limit = props.tableRowData?.required ? props.tableRowData?.required : 5;
        return yup.number()
          .required(`${field.label} is a required field`)
          .test(
            'is-valid-invoice-number',
            `Number of items received is less than or equals to ${limit}`,
            value => value && value >= 0 && value <= limit // Additional test to ensure value is not negative
          );
      } else {
        return yup.number().required(`${field.label} is a required field`);
      }
    }

    else if (field.type === 'email') {
      return yup.string().email().required(`${field.label} is a required field`);
    } else if (field.type === 'password' && field.name === 'password') {
      return yup.string().required().matches(getPasswordRegex(), "Password must contain at least 8 characters, one uppercase, one number and one special case character")
    } else if (field.type === 'password' && field.name === 'confirmPassword') {
      return yup.string().required().oneOf([yup.ref('password'), null], 'Password and confirm password do not match');
    }
    else if ((field.type === "relateAutoComplete" && field.isMultiple) || (field && field.isMultiSelect && field.type == 'dropDown')) {
      return yup.array().required(`${field.label} is a required field`)
    } else if ((field.type === "relateAutoComplete" && formType === "add") || field.type === "permission" || (field.type == 'dropDown' && field.returnType === 'Object')) {
      return yup.object().required(`${field.label} is a required field`)
    }
    /**@CheckBox */
    else if (field.type === 'checkbox') {
      if (field.required) {
        return yup.boolean().oneOf([true], 'Checkbox must be checked')
      } else {
        yup.boolean();
      }
    }
    /**@Text Validation */
    else if (field.type == 'text' && field.regexPattern) {
      let str = disp(field);
      return yup.string().required(`${field.label} is a required field`).matches(new RegExp(field.regexPattern), `${str}`);
    }

    else if (field.type === 'WebSite') {
      const websiteRegex = /^(?:(?:(?:https?|ftp):)?\/\/)?(?:www\.)?[a-zA-Z0-9-]+(?:\.[a-zA-Z]{2,})+(?:\/[^\s]*)?$/;

      return yup.string().required(`${field.label} is a required field`).matches(websiteRegex, `Enter a valid URL`);
    }

    else {
      return yup.string().required(`${field.label} is a required field`);
    }
  }

  let disp = (f) => {
    let msg = "";
    let m = '';
    if (f.regexData.maxLengthRequired == true && f.regexData.showMaxField == true) {
      m = "Max length:" + f.regexData.maxLengthText + ", ";
      msg += m;
    }
    if (f.regexData.minLengthRequired == true && f.regexData.showMaxField == true) {
      m = "Min length:" + f.regexData.minLengthText + ", ";
      msg += m;
    }
    if (f.regexData.alphabetsRequired == true && f.regexData.showAlphaField == true) {
      m = "Letters" + ", ";
      msg += m;
    }
    if (f.regexData.numbersRequired == true && f.regexData.showNumField == true) {
      m = "Numbers" + ", ";
      msg += m;
    }
    if (f.regexData.showSCharField == true && f.regexData.specialCharRequired == true) {
      m = "Special characters:" + f.regexData.specialCharText + ", ";
      msg += m;
    }
    msg = msg.slice(0, -2);
    return msg;

  };


  let onPageChange = async (event) => {
    let filterCriteria = filterCriteria;
    if (event && event.rows) {
      let currentPage = event.page + 1;
      filterCriteria['limit'] = event.rows;
      filterCriteria['page'] = currentPage;
      setRows(event.rows);
      setPage(event.page);
      setFirst(event.first)
    }
    getActivities()
  }
  let getTableFields = () => {
    let data = [
      {
        show: true,
        mobile: true,
        textAlign: 'center',
        width: 100,
        field: 'created',
        fieldType: 'Date',
        type: 'date',
        header: 'Created',
        filter: true,
        sortable: true,
        dateFormat: config.dateDayMonthFormat
      },
      {
        show: true,
        mobile: true,
        textAlign: 'center',
        width: 100,
        field: 'contextType',
        header: 'Context Type',
        filter: true,
        sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        width: 250,
        fieldType: 'Array',
        field: 'description',
        header: 'Description',
        filter: true,
        sortable: true
      },
      /**not using For Now */
      // {
      //     textAlign: 'center',
      //     width: 100,
      //     field: 'ipAddress',
      //     header: 'Ip Address',
      //     filter: true,
      //     sortable: true
      // },
      // {
      //     textAlign: 'center',
      //     width: 100,
      //     field: 'deviceType',
      //     header: 'Device Type',
      //     filter: true,
      //     sortable: true
      // },
      // {
      //     textAlign: 'center',
      //     width: 100,
      //     field: 'browserName',
      //     header: 'Browser',
      //     filter: true,
      //     sortable: true
      // },
      // {
      //     textAlign: 'center',
      //     width: 100,
      //     field: 'osName',
      //     header: 'Os Name',
      //     filter: true,
      //     sortable: true
      // },
      // {
      //     textAlign: 'center',
      //     width: 100,
      //     field: 'osVersion',
      //     header: 'Os Version',
      //     filter: true,
      //     sortable: true
      // },


    ];
    return data;
  };
  let getPaginator = () => {
    return (
      <PaginatorComponent
        totalRecords={totalRecordsLength}
        first={first}
        rows={rows}
        onPageChange={onPageChange}
        isWeb={true}
      />
    )
  }
  let getActivities = async () => {
    let tFilterCriteria = filterCriteria;
    let urlHeading = window.location.href;
    let userID = decodeURIComponent(urlHeading.split('/').pop());
    let userIDWithoutQuotes = userID.replace(/^"(.*)"$/, '$1');
    userIDWithoutQuotes = userIDWithoutQuotes.replace(/"/g, '');
    tFilterCriteria['criteria'] = [{ key: 'contextId', value: userIDWithoutQuotes, type: 'eq' }];
    let url = `activities?filter=${JSON.stringify(tFilterCriteria)}`
    return fetchMethodRequest('GET', url).then(async (response) => {
      if (response) {
        let responseData = '',
          dateFormat;
        if (response && response['activities'] && response['activities'].length && response['activities'].length >= 0) {
          if (response.pagination && response.pagination.totalCount) {
            totalRecordsLength = response.pagination.totalCount;
          }
          responseData = updateDateFormat(response['activities'], dateFormat);
        } else {
          if (response.pagination && (response.pagination.totalCount || response.pagination.totalCount == 0)) {
            totalRecordsLength = response.pagination.totalCount;
          }
        }

        setActivitiesData(responseData);
        setTotalRecordsLength(totalRecordsLength);
        setFilterCriteria(tFilterCriteria)

      }
    }).catch((err) => {
      return err
    })
  }

  let getTableFieldItem = async (field) => {
    for (let i = 0; i < props.tablefieldsToShow.length; i++) {
      if (props.tablefieldsToShow[i].field == field) {
        return props.tablefieldsToShow[i];
      }
    }
    return null;
  }
  /**not using For Now */
  // getFormFields = async () => {
  //   if (props.formFields) {
  //     let formFields = await props.formFields();
  //     await setState({ formFields: formFields, });
  //   }
  // }
  let getFormFields = async () => {
    if (props.formFields()) {
      let formFields = await props.formFields(props.tabType ? props.tabType : null);
      let sortType = '';
      if (props.formType === 'add') {
        sortType = 'addFormOrder';
      } else if (props.formType === 'edit') {
        sortType = 'editFormOrder';
      }
      if (sortType) {
        let sortFields = [];
        let otherFields = [];
        for (let i = 0; i < formFields.length; i++) {
          if (formFields[i][sortType]) {
            sortFields.push(formFields[i]);
          } else {
            otherFields.push(formFields[i]);
          }
        }
        sortFields = sortFields.sort((a, b) => a[sortType] - b[sortType]);
        formFields = [...sortFields, ...otherFields];
        formFields = formFields.sort((a, b) => a[sortType] - b[sortType]);
      }
      await setFormFields(formFields);
      await setValidations(formFields);

    }
  }

  let getFormFieldItem = async (key) => {
    let formFields = await props.formFields(props.tabType ? props.tabType : null);
    for (let i = 0; i < formFields.length; i++) {
      if (formFields[i].name === key) {
        return formFields[i];
      }
    }
    return null;
  }
  //close delete modal
  let closeDeleteModal = async () => {
    setState({
      openDeleteModal: false,
      actions: ''
    })
  }
  let handleNextAndBackActions = async () => {
    let { allUsersData } = props;
    let data = '';
    data = allUsersData[slno];
    if (formType === 'view') {
      setEditRowDataID(data['_id']);
      await handleViewDisplay(data, 'view');
    } else {
      await getRowData(data, 'edit')
    }
    setFilterCriteria({ limit: 10, page: 1, criteria: [], sortfield: 'created', direction: 'desc' });
    setFirst(0);
    setRows(10);
    setTotalRecordsLength(0)
    await getActivities();
  }

  let getViewData = async (rowData, type, rowDataIndex, userData, _id) => {
    setRowData(rowData);
    setEditRowDataID(_id);
    setFormType(type);
    setSslno(rowDataIndex);
    setUserData(userData);
  };
  let getIconValue = (rowData, labelKey) => {
    if (labelKey && labelKey.options && labelKey.options.length > 0) {
      for (let i = 0; i < labelKey.options.length; i++) {
        if (labelKey.options[i].value === rowData[labelKey.field]) {
          return labelKey.options[i].displayText;
        }
      }
    }
    return '';
  }
  let handleViewDisplay = async (rowData, type) => {
    await props.getDataFromServer(props.filterCriteria)
    let _id = rowData['_id'];
    let rowDataIndex = getUserData(rowData['_id'])
    let keys = Object.keys(rowData);
    let formFields = [];
    if (formFields) {
      if (props.type) {
        if (rowData) {
          let values, fieldType, searchField, self = this, icon = false;

          // hari get all the labels from 
          keys.forEach(async function (key) {
            let labelKey = await getTableFieldItem(key);
            if (labelKey == null) {
              labelKey = key;
            } else {
              let val = rowData[key];
              if (labelKey.fieldType === 'icon') {
                val = self.getIconValue(rowData, labelKey);
                icon = true;
              }
              fieldType = labelKey.fieldType ? labelKey.fieldType : null
              searchField = labelKey.searchField ? labelKey.searchField : null
              let options = labelKey.options ? labelKey.options : []
              labelKey = labelKey.header
              if (val) {
                if (fieldType && searchField && fieldType == "relateAutoComplete") {
                  values = {
                    label: labelKey,
                    value: icon ? val : rowData[key][searchField],
                    fieldType: fieldType
                  }
                } else {
                  values = {
                    label: labelKey,
                    value: icon ? val : rowData[key],
                    fieldType: fieldType,
                    options: options
                  }
                }
                formFields.push(values);
                icon = false;
              }
            }
            /**not using For Now */
            // if (key == 'fromTime' || key == 'toTime') {
            //   let date = dateFormats.addDaysToDate(rowData[key], 1);
            //   date = dateFormats.formatDate(date, config.timeFormat);
            //   values = {
            //     label: key,
            //     value: date
            //   }
            //   formFields.push(values);
            // }
          });
        }
      }

      setFormType('view');
      setUserData(rowData);
      setDisplayBreadCrumbValue(rowData[displayBreadCrumbField]);
      setUserStatus(rowData['status'])
      await getViewData(formFields, 'view', rowDataIndex, rowData, _id);
    }
  }
  let getUserData = (_id) => {
    let data = props.allUsersData;
    for (let i = 0; i < data.length; i++) {
      if (data[i]['_id'] === _id) {
        return i
      }
    }
  }
  let updateDateFormat = (itemdata, dateFormat) => {
    let modifiedData = [];
    let tablefieldsToShow = getTableFields();
    for (let i = 0; i < itemdata.length; i++) {
      for (let k = 0; k < tablefieldsToShow.length; k++) {
        if ("Date" == tablefieldsToShow[k]['fieldType']) {
          itemdata[i][tablefieldsToShow[k]['field']] =
            dateFormats.formatDate(
              itemdata[i][tablefieldsToShow[k]['field']],
              tablefieldsToShow[k]['dateFormat']);
        }
      }
      modifiedData.push(itemdata[i])
    }
    return modifiedData;
  }
  //Get From Fields data on Edit
  let getRowData = async (selectedRowInfo, type) => {
    if (props.customFormDataSet) {
      selectedRowInfo = await props.customFormDataSet(selectedRowInfo)
    }
    setRowInform(selectedRowInfo)
    if (selectedRowInfo && selectedRowInfo.minInventoryByStateName) {
      setTimeout(() => { setCheckBoxChecked(true); }, 1000)

    }
    let keys = Object.keys(selectedRowInfo);
    for (let i = 0; i < keys.length; i++) {
      let fieldItem = await getFormFieldItem(keys[i]);
      if (fieldItem) {
        /**not using For Now */
        // if ((fieldItem.type === 'multipleprofile' || fieldItem.type === 'ckeditor' || fieldItem.type === 'profile') && selectedRowInfo[fieldItem.name]) {
        //   setState({
        //     [fieldItem.name]: selectedRowInfo[fieldItem.name]
        //   })
        // }
        if (fieldItem.type === 'date') {
          let formattedDate = fieldItem.dateFormat ? fieldItem.dateFormat : config.dateDayMonthFormat;
          selectedRowInfo[fieldItem.name] = moment(selectedRowInfo[fieldItem.name], formattedDate).toDate();
        }
        if (fieldItem.type === 'time') {
          let formattedTime = moment(selectedRowInfo[fieldItem.name], config.fullDateTimeFormat).toDate();
          selectedRowInfo[fieldItem.name] = formattedTime;
        }
        if (fieldItem.type === 'dropDown' && fieldItem.isMultiSelect) {
          selectedRowInfo[fieldItem.name] = selectedRowInfo[fieldItem.name]
        } else if (fieldItem.type === 'dropDown' && fieldItem.dependent && fieldItem.dependent.length > 0) {
          let formFields = formFields;
          if (fieldItem.dependent && fieldItem.dependent.length > 0) {
            for (let i = 0; i < fieldItem.dependent.length; i++) {
              if (selectedRowInfo && selectedRowInfo[fieldItem.name] === Object.keys(fieldItem.dependent[i])[0]) {
                if (fieldItem.dependent[i][Object.keys(fieldItem.dependent[i])[0]] && fieldItem.dependent[i][Object.keys(fieldItem.dependent[i])[0]].length > 0) {
                  formFields = await showField(formFields, fieldItem.dependent[i][Object.keys(fieldItem.dependent[i])[0]], true);
                }
              }
            }

            // await setState({ formFields: formFields });
            await setFormFields(formFields);
          }
        }
        if (props.type === 'Min Inventory By Products' && selectedRowInfo && selectedRowInfo.categoryType && selectedRowInfo.categoryType === 'Medicine') {
          setShowVolumeField(true)
        }
        if (props.type === 'Storage Units') {
          setShowVolumeField(true)
        }
      }
    }
    if (props.type && props.type == "Roles" && selectedRowInfo.roleType && selectedRowInfo.roleType == "Manager") {
      setShoworHideLevelsField(true);
    }

    if (props.load) {
      props.load(selectedRowInfo);
    }

    let fields = props.formFields(props.tabType ? props.tabType : null);
    fields.forEach((item) => {
      if (!(item.isEditFormHidden === true && formType === 'edit')) {
        setValue(item.name, selectedRowInfo[item.name])
      }
    })
    setIsLoading(false);
    setEditRowDataID(selectedRowInfo._id);
    setDisplayBreadCrumbValue(selectedRowInfo[displayBreadCrumbField]),
      setFormType(type);
    setUserData(selectedRowInfo);
  }

  let closeFormModal = async () => {
    clearFormFields();
    // props.reset();
    if (["Inventory Check Lists", "Storage Units", "MissingItems", "Expired Items", "Orders"].includes(props.type)) {
      props.closeFormModal();
    }

    else {
      props.getDataFromServer(props.filterCriteria, props.categoryType);
      props.closeFormModal();
    }
  }

  let flattenArray = (arrayVal) => {
    let val = '';
    if (arrayVal) {
      val = JSON.stringify(arrayVal);
      val = val.replace(/"/g, '')
        .replace(/\[/g, '')
        .replace(/]|\\/g, '')
        .replace(/{/g, '')
        .replace(/}/g, '')
        .replace(/,/g, ' , ')
        .replace(/:/g, ' : ');
    }
    return val;
  }
  let getActivtiesTableFieldItem = (field) => {
    let tablefieldsToShow = getTableFields()
    for (let i = 0; i < tablefieldsToShow.length; i++) {
      if (tablefieldsToShow[i].field == field) {
        return tablefieldsToShow[i];
      }
    }
    return null;
  }
  // hari need to move to derived class or controller
  /**not using For Now */
  let changeFieldValues = (item, column) => {
    let self = this, tableItem;
    tableItem = self.getActivtiesTableFieldItem(column.field);
    if (tableItem.fieldType === "Array") {
      let val = flattenArray(item[column.field]);
      return <span style={tableItem.style} title={val}>
        {val}
      </span>
    } else {
      // if (item[column.field] === 0) {
      //   return item[column.field];
      // }
      if ((item[column.field]) && typeof item[column.field] !== 'object') {
        return item[column.field];
      }
    }
  }
  /**not using For Now */
  // changeFieldValues = async (item, column) => {
  //   let self = this, tableItem;
  //   tableItem = self.getActivtiesTableFieldItem(column.field);
  //   if (tableItem.fieldType === "Array") {
  //     let val = self.flattenArray(item[column.field]);
  //     return <span style={tableItem.style} title={val}>
  //       {val}
  //     </span>
  //   } else {
  //     // if (item[column.field] === 0) {
  //     //   return item[column.field];
  //     // }
  //     if ((item[column.field]) && typeof item[column.field] !== 'object') {
  //       return item[column.field];
  //     }
  //   }
  // }
  // form Submit
  // let submit = (values) => {
  //   console.log("values>>>>>>>>>>>>.", values)
  //   if (values && Object.keys(values).length > 0) {
  //     saveDataToServer(values);
  //   } else {
  //     return;
  //   }
  // }

  let submit = (values) => {
    if (values && Object.keys(values).length > 0) {
      if (props.customFormDataManuplate) {
        values = props.customFormDataManuplate(values)
      }
      saveDataToServer(values);
    } else {
      return;
    }
  }

  let clearFormFields = async () => {
    if (props.load) {
      props.load({});
    }
    let formData = [...formFields];
    formData.forEach((item) => {
      item.value = '';
      item.invalid = false;
      item.message = ''
    });
    await setFormFields(formData)
    getFormFields();
  }

  let onEditorChange = async (evt) => {
    setState({
      [evt.editor.name]: evt.editor.getData()
    });
  }
  let ObjectbyString = (o, s) => {
    s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
    s = s.replace(/^\./, '');           // strip a leading dot
    var a = s.split('.');
    for (var i = 0, n = a.length; i < n; ++i) {
      var k = a[i];
      if (k in o) {
        o = o[k];
      } else {
        return;
      }
    }
    return o;
  }
  //send data to server
  let saveDataToServer = async (formValues) => {
    /**@CheckBox */
    Object.keys(formValues).forEach(key => {
      if (formValues[key] === undefined) {
        let formField = formFields.filter(d => d.name == key);
        if (formField && formField[0] && formField[0].type && formField[0].type == 'checkbox') {
          formValues[key] = "false";
        }
      }
    });
    let userBody = Object.assign({}, formValues);
    let validationExists;
    const loginCredentials = localStorage.getItem("loginCredentials");
    const credentialsObject = JSON.parse(loginCredentials);
    let loginRole = credentialsObject.role
    const companyId = credentialsObject._id;

    setIsLoading(true);
    if (!validationExists) {
      if (formValues) {
        let method, apiUrl;
        if (formType === 'edit') {
          // delete userBody.email
          delete userBody.password;
          method = 'PUT';
          apiUrl = `${props.apiUrl}/${editRowDataID}`;
          userBody.loginRole = `${loginRole}`;
          if (props.type !== 'Employees' && props.type !== 'Companies') {
            userBody.companyId = loginRole === 'Admin' ? `${companyId}` : loginRole === 'Super Admin' ? `${props.adminCompanyId}` : null;
          }
          if (props.tabType === "Orders List") {
            apiUrl = `orders/updateOrderRequired`;
            userBody.orderId = editRowDataID;
          }
          if (props.tabType == "Placed Orders") {
            apiUrl = `orders/updateOrder`;
            let numberOfItemsReceived = userBody.numberOfItemsRecieved;
            userBody.required = numberOfItemsReceived;
            userBody.orderId = editRowDataID;

          }
          else if (props.type === 'Companies') {
            apiUrl = `${"companyUsers"}/${editRowDataID}`;
            userBody.orderId = editRowDataID;
          }
          // else if (props.type === 'Orders') {
          //   if (userBody) {
          //   }

          // }
        }
        else if (formType === 'view') {
          delete userBody.email
          delete userBody.password;
          method = 'PUT';
          apiUrl = `${props.apiUrl}/${editRowDataID}`;

        } else {
          method = 'POST';
          apiUrl = props.apiUrl;
          const loginCredentials = localStorage.getItem("loginCredentials");
          const credentialsObject = JSON.parse(loginCredentials);
          let loginRole = credentialsObject.role
          const companyId = credentialsObject._id;
          userBody.loginRole = `${loginRole}`;
          if (props.type === 'Facility Staffs') {
            if (userBody && userBody.facilities) {
              const facilities = userBody.facilities;
              facilities.forEach(facility => {
                facility.facilityId = facility.value;
                facility._id = facility.value;
                facility.facilityName = facility.label;
              });
            }
          }
          if (props.type !== 'Employees' && props.type !== 'Companies') {
            userBody.companyId = loginRole === 'Admin' ? `${companyId}` : loginRole === 'Super Admin' ? `${props.adminCompanyId}` : null;
          }
          if (props.type === 'Companies') {
            apiUrl = `companyUsers/register`;
          }
        }

        for (let i = 0; i < formFields.length > 0; i++) {
          if (formFields[i].show === false)
            continue;
          if (formFields[i].type === 'autoComplete') {
            if (formFields[i]["options"] && formFields[i]["options"].length > 0) {
              for (let j = 0; j < formFields[i]["options"].length; j++) {
                let keys = Object.keys(formFields[i]["options"][j])
                let values = Object.values(formFields[i]["options"][j]);
                if (keys && keys[0] && values && values[0] && formValues[formFields[i]["name"]][values[0]]) {
                  userBody[keys[0]] = formValues[formFields[i]["name"]][values[0]];
                }
              }
            }
          }
          if (formFields[i].type === 'multipleprofile' || formFields[i].type === 'profile') {
            // userBody[formFields[i].name] = formFields[i].name;
            userBody[formFields[i].name] = userBody[formFields[i].name];
          }
          if (formFields[i].type === 'permission') {
            let permissions = {};
            let keys = Object.keys(formValues);
            menuList.forEach((item, index) => {
              keys.forEach((key) => {
                if (item.title === key) {
                  permissions[item.title] = formValues[key]
                }
              })
            })
            userBody.permissions = { ...permissions }
          }
        }
        if (props.type === 'Orders' && props.tabType == "Orders List") {
          if (formType === "edit" && selectedRowData) {
            const loginCredentials = localStorage.getItem("loginCredentials");
            const credentialsObject = JSON.parse(loginCredentials);
            userBody.storageUnitId = selectedRowData.storageUnitId
            userBody.oldDistributorEmail = selectedRowData.distributorEmail
            userBody.productId = selectedRowData.productId;
            userBody.companyId = selectedRowData.companyId;
            userBody.categoryType = selectedRowData.categoryType
          }
        } else if (props.type === 'Facilities') {
          if (formType === 'edit' && selectedRowData) {
            userBody.facilityId = selectedRowData._id;
          }
        }
        if (props.type === 'Facility Staffs') {
          if (userBody && userBody.facilities) {
            const facilities = userBody.facilities;
            facilities.forEach(facility => {
              facility.facilityId = facility.value;
              facility._id = facility.value;
              facility.facilityName = facility.label;
            });
          }
        }
        if (props.type === 'Orders' && props.tabType === "Placed Orders") {
          if (formType === "edit" && selectedRowData) {
            console.log("selectedRowData", selectedRowData)
            userBody.status = selectedRowData.status
            userBody.active = selectedRowData.active
            userBody.productName = selectedRowData.productName
            userBody.orderedBy = selectedRowData.orderedBy
            userBody.storageUnitName = selectedRowData.storageUnitName
            userBody.description = selectedRowData.description
            userBody.volume = selectedRowData.volume
            userBody.facilityName = selectedRowData.facilityName
            userBody.facilityId = selectedRowData.facilityId
            // userBody.required = selectedRowData.required
            userBody.distributorEmail = selectedRowData.distributorEmail
            userBody.grouped = selectedRowData.grouped
            userBody.created = selectedRowData.created
            userBody.checked = selectedRowData.checked
            userBody.invoiceNumber = selectedRowData.invoiceNumber
            userBody.storageUnitId = selectedRowData.storageUnitId;
            userBody.productId = selectedRowData.productId;
            userBody.companyId = selectedRowData.companyId;
            userBody.categoryType = selectedRowData.categoryType

          }
        }

        if (props.type === "Storage Units" && props.tabType) {
          if (formType === "edit" && selectedRowData) {
            userBody.facilityName = formValues.facilityName.facilityName ? formValues.facilityName.facilityName : selectedRowData.facilityName;
            userBody.facilityId = formValues.facilityName._id ? formValues.facilityName._id : selectedRowData.facilityId;
            userBody._id = selectedRowData._id;
            if (props.tabType !== "OR Suites") {
              userBody.selectedInventoryType = "recommended"
            }
          } else {
            userBody.facilityName = formValues.facilityName.facilityName;
            userBody.facilityId = formValues.facilityName._id;
            if (props.tabType !== "OR Suites") {
              userBody.selectedInventoryType = "recommended"
            }
          }

          const storageTypes = { "Cart Location": 'cartLocation', "Supply Cabinets": 'supplyCabinet', "OR Suites": 'orSuit' };
          userBody.storageUnitType = storageTypes[props.tabType];

          if (props.tabType in storageTypes) {
            apiUrl = `${apiUrl}?storageUnitType=${storageTypes[props.tabType]}`;
          }
        }
        else if (
          ["MissingItems", "Expired Items", "Inventory Check Lists"].includes(props.type) &&
          props.tableRowData &&
          props.storageUnitId && props.screen == "wishlist"
        ) {
          let wishlistData = props.tableRowData;
          let storageunitId = props.storageUnitId;
          userBody.productId = wishlistData.productId;
          userBody.storageUnitName = wishlistData.storageUnitName;
          userBody.storageUnitId = storageunitId;
          // if (props.type !== 'MissingItems') {
          userBody.required = formValues.needToOrder

          // }
        }
        else if (props.type == "Inventory Check Lists") {
          userBody.productId = formValues.productName ? formValues.productName && formValues.productName.productId : null
          userBody.storageUnitId = storageUnitId

        } else if (props.type === "Min Inventory By Products") {
          userBody.storageUnitName = formValues.storageUnitName;
          userBody.storageUnitId = storageUnitId;
          userBody.productId = formValues.productName._id;
          userBody.categoryId = formValues.productName.categoryId
          userBody.productName = formValues.productName.productName
        }
        if (formValues && formValues.lotId && props.type == "Recall Items") {
          const loginCredentials = localStorage.getItem("loginCredentials");
          const credentialsObject = JSON.parse(loginCredentials);
          // const companyId = credentialsObject._id;
          let storageUnitId = formValues.lotId.storageUnitId
          let productId = formValues.lotId.productId
          userBody.productId = productId
          userBody.storageUnitId = storageUnitId
          userBody.lotId = formValues.lotId.lotId
          // userBody.companyId = companyId;
        }

        return fetchMethodRequest(method, apiUrl, userBody)
          .then(async (response) => {
            let sessionexpired = await localStorage.getItem('sessionexpired')
            if (sessionexpired === "true") {
              setSessionExpiryModal(true);
            }
            if (response && response.respCode) {
              if (props.type === "Storage Units") {
                await props.getDataFromServer(props.filterCriteria, props.tabType);
              } else if (props.type === "Orders" && props.tabType === 'Placed Orders') {
                await props.getDataFromServer(props.filterCriteria, "Placed Orders");
              } else {
                await props.getDataFromServer(props.filterCriteria, props.categoryType);
              }
              showToasterMessage(response.respMessage, 'success');
              if (props.displayViewOfForm === 'modal') {
                props.closeFormModal('save', response.quantityId);
              } else {
                if (formType !== 'add' && (props.type !== 'Storage Units' && formType !== 'edit')) {
                  window.location.href = `/${props.routeTo}`;
                } else {
                  props.closeFormModal('save', response.quantityId);
                }

              }
              clearFormFields();
              props.reset();
            } else if (response && response.errorMessage) {
              showToasterMessage(response.errorMessage, 'error');
            }

            setIsLoading(false);
          }).catch((err) => {
            return err;
          });
      } else {
        return;
      }
    }
  }

  //getStores
  let getRecords = (valve, key, apiUrl, name) => {
    let filterCriteria = {}
    filterCriteria['criteria'] = [{ key: key, value: valve, type: 'eq' }];
    let url = `${apiUrl}?filter=${JSON.stringify(filterCriteria)}`
    fetchMethodRequest('GET', url).then(async (response) => {
      if (response) {
        let states = response[apiUrl];
        let roleOptions = [];
        if (states && states.length > 0) {
          for (let i = 0; i < states.length; i++) {
            roleOptions.push({ label: states[i][name], value: states[i][name] })
          }

          await setRoleOptions(roleOptions);
        } else {
          await setRoleOptions([]);
        }
      }
    }).catch((err) => {
      return err
    })
  }

  // //Handle auto complete data
  const handleAutoCompleteData = async (value, name) => {
    if (value) {
      if (props.type == "Min Inventory By Products") {
        if (name === "productName") {
          setValue('description', value.description);
          setValue('categoryName', value.categoryName);
          setValue('volume', value.volume);
          setValue('inventoryType', value.inventoryType);
        }
      }
      // setIsProductName(value.productName)

      if (value && value.productName && props.type === 'Recall Items') {
        setValue("productName", value.productName)
      }
    }
  }
  //handleDrodownData



  // Handle Drop down Data
  const handleDrodownData = (value, name, item) => {
    if (name === 'minInventoryByStateName') {
      setOptionsValue(value);
      setElementName(name);
    }

    if (value) {
      setCheckBoxChecked(false);
    }

    if (props.type === "Min Inventory By Products" && name === "storageUnitType") {
      if (value) {
        fetchMethodRequest(
          "GET",
          `storageUnits?filter={"limit":"all","page":1,"criteria":[],"sortfield":"created","direction":"desc"}&storageUnitType=${value ? value : null}`
        ).then(async (response) => {
          let locations = [];
          if (response && response.storageunits) {
            for (let location of response.storageunits) {
              locations.push({
                label: location.storageUnitName,
                value: location.storageUnitName,
                showIcon: true
              });
            }
            await setInvLocations(locations);
          }
        });
      }
    }

    if (name === "minInventoryByStateName") {
      if (value) {
        let stateId = item.options.find((stateName) => stateName.state.minInventoryByStateName == value);
        setValue('minInventoryByStateId', stateId.state._id);
      }
    }
  };


  let getDropdownMultiselect = (i, item) => {
    return (
      /**not using For Now */
      // <div className="form__form-group-field mb-2">
      //   <Field key={i}
      //     name={item.name}
      //     component={MultiSelectDropDown}
      //     id={item.id}
      //     validate={[required]}
      //     filterElement={roleOptions && roleOptions.length > 0 ? roleOptions : item.options ? item.options : null}
      //     maxSelectedLabels={maxSelectedLabels ? maxSelectedLabels : null}
      //   />
      // </div>
      <div>
        <Controller
          name={item.name}
          control={control}
          render={({ field, fieldState }) => (
            <DoMultiSelectField
              markReq={item.required}
              input={field}
              id={field.id}
              name={field.name}
              field={field}
              fieldState={fieldState}
              errors={errors}
              label={item.label}
              options={item.options}
              maxSelectedLabels={4}
              optionLabel={item.optionLabel}
              placeholder={item.placeholder}
            />)}
        />
      </div>
    )
  }

  let handleFnEnableControlsBasedOnValue2 = async (e, dependent) => {
    let formFields = formFields;
    if (dependent && dependent.length > 0) {
      for (let i = 0; i < dependent.length; i++) {
        if (e && e === Object.keys(dependent[i])[0]) {
        }
        else {
          if (dependent[i][Object.keys(dependent[i])[0]] && dependent[i][Object.keys(dependent[i])[0]].length > 0) {
            formFields = await showField(formFields, dependent[i][Object.keys(dependent[i])[0]], false)
          }
        }
      }
      for (let i = 0; i < dependent.length; i++) {
        if (e && e === Object.keys(dependent[i])[0]) {
          if (dependent[i][Object.keys(dependent[i])[0]] && dependent[i][Object.keys(dependent[i])[0]].length > 0) {
            formFields = await showField(formFields, dependent[i][Object.keys(dependent[i])[0]], true);
          }
        }
      }
      await setFormFields(formFields)
    }
  }

  // Autocopmplete Dependent values
  let handleFnEnableControlsBasedOnValue1 = async (field, show) => {
    let newFormField = formFields;
    newFormField = await showField(newFormField, field, show);
    let requiredFields = [];
    newFormField.forEach(field => {
      if ('required' in field && field.required == true) {
        requiredFields.push(field);
      }
    });
    let tschema = setValidations(requiredFields);
    setSchema(tschema)
    await setFormFields(newFormField)
  }


  let handleFnEnableControlsBasedOnValue = async (e, dependent) => {
    if (e && e == "Manager") {
      setShoworHideLevelsField(true);
    } else {
      setShoworHideLevelsField(false);
    }
  }

  let getDropdown = (i, item) => {

    const isDisable = props.formType === 'add' && item.isAddFieldDisable ? true : props.formType === 'edit' && item.isEditFieldDisable ? true : false;

    return (
      /**not using For Now */
      // <div className="form__form-group-field mb-2">
      //   <Field key={i}
      //     name={item.name}
      //     component={Select}
      //     options={item.options}
      //     onChange={props.type && props.type == "Roles" ? (e) => handleFnEnableControlsBasedOnValue(e, item.dependent) : (e) => handleFnEnableControlsBasedOnValue2(e, item.dependent)}
      //     defaultValue={props.formType && props.formType === 'add' ? item.defaultValue : null}
      //     placeholder={item.placeholder}
      //     isDisable={item.disable ? item.disable : false}        
      //   />
      // </div>
      <div>
        {/* {props.type === 'Storage Units' && item.name === 'minInventoryByStateName' ? */}
        <Controller
          name={item.name}
          control={control}
          render={({ field, fieldState }) => (
            <DoSelectField
              input={field}
              markReq={item.required}
              id={field.id}
              name={field.name}
              field={field}
              formatOptionLabel={true}
              label={item.label}
              fieldState={fieldState}
              errors={errors}
              showIcon={true}
              isDisable={isDisable}
              options={(item.name === "storageUnitName" ? Invlocations : item.options)}
              onChange={(event) => handleDrodownData(event, item.name, item)}
              optionLabel={item.optionLabel ? item.optionLabel : 'label'}
              placeholder={item.placeholder}
            />)}
        />
        {/* : null} */}
      </div>
    )
  }


  let showField = (formFields, itemNames, show) => {
    for (let i = 0; i < formFields.length; i++) {
      if (Array.isArray(itemNames)) {
        for (let itemName of itemNames) {
          if (formFields[i].name === itemName) {
            formFields[i].show = show;
            setShowVolumeField(show)
          }
        }
      } else {
        if (formFields[i].name === itemNames) {
          formFields[i].show = show;
          setShowVolumeField(show)
          // formFields[i].isAddFormHidden = !show;
          // formFields[i].isEditFormHidden = !show;
        }
      }
    }
    return formFields;
  }


  // const getScreenPermissions = () => {
  //   setValue('permission', {})
  //   setValue('levels', 1)
  //   const options = [
  //     { label: 'NoView', value: 'NoView' },
  //     { label: 'View', value: 'View' },
  //     { label: 'Edit', value: 'Edit' }
  //   ];
  //   let permissions = {};
  //   if (props.formType === 'edit') {
  //     const rowData = getSelectedData(props.selectedId, props.allUsersData);
  //     if (rowData.permissions && typeof rowData.permissions === 'object') {
  //       permissions = { ...rowData.permissions }
  //     }
  //   }
  //   const values = getValues();
  //   const menu = menuList.map((screen) => {
  //     if (screen.name && !values[screen.name]) {
  //       setValue(screen.name, permissions[screen.name] ? permissions[screen.name] : 'View')
  //     }
  //     return { 'name': screen.name, 'label': screen.displayTitle, 'value': permissions[screen.name] ? permissions[screen.name] : 'View', 'options': options }
  //   });


  //   return (
  //     <div className='row '>
  //       {
  //         (menu && menu.length > 0) &&
  //         menu.map((item, index) => {
  //           return <div key={index} className='col-md-6 mb-3'>
  //             {getRadio(index, item)}
  //           </div>
  //         })
  //       }
  //     </div>
  //   )
  // }


  const generatePermissionsData = () => {
    const options = [
      { label: 'NoView', value: 'NoView' },
      { label: 'View', value: 'View' },
      { label: 'Edit', value: 'Edit' }
    ];
    let permissions = {};
    if (props.formType === 'edit' && props.selectedId) {
      const rowData = props.allUsersData.find((obj) => obj._id === JSON.parse(props.selectedId));
      if (rowData && rowData.permissions && typeof rowData.permissions === 'object') {
        permissions = { ...rowData.permissions };
      }
    }
    else if (props.formType == "edit" && props.tableRowData) {
      let rowData = props.tableRowData;
      if (rowData && rowData.permissions && typeof rowData.permissions === 'object') {
        permissions = { ...rowData.permissions };
      }
    }

    return menuList.map((screen) => {
      return screen.name ? {
        'name': screen.name,
        'label': screen.name,
        'value': permissions[screen.name] ? permissions[screen.name] : 'View',
        'options': options
      } : null;
    }).filter(item => item !== null);
  };

  const getScreenPermissions = () => {
    const permissionsData = generatePermissionsData();

    return (
      <div className='row '>
        {permissionsData && permissionsData.length > 0 && permissionsData.map((item, index) => (
          <div key={index} className={`col-md-${props.displayViewOfForm === 'sidebar' ? '12' : '6'} mb-3`}>
            {getRadio(index, item)}
          </div>
        ))}
      </div>
    );
  };

  let getRolePermissions = () => {
    return (
      <div className="form form--horizontal">
        <div className="form__form-group row">
          <div className='col-sm-6' style={{ paddingLeft: '119px' }}>
            <span className='pr-4 pl-5'>{'Edit'}</span>
            <span className='pr-3 pl-2'>{'View'}</span>
            <span className='pr-2 '>{'No View'}</span>
          </div>
          <div className='col-sm-6' style={{ paddingLeft: '119px' }}>
            <span className='pr-4 pl-5'>{'Edit'}</span>
            <span className='pr-3 pl-2'>{'View'}</span>
            <span className='pr-2 '>{'No View'}</span>
          </div>
        </div>
        {menuList && menuList.length > 0 ?
          menuList.map((item, index) => {
            return <div key={index} className="form__form-group col-sm-6">
              <span className="form__form-group-label ">{item.title}</span>
              <div className="form__form-group-field ">
              </div>
            </div>
          }) : null
        }
      </div>
    );
  }

  let getProfile = (i, item) => {
    return (
      <div>
        <Controller
          name={item.name}
          control={control}
          render={({ field, fieldState }) => (
            <DoFileUpload
              url={`uploads?uploadPath=${item.imagePath.toLowerCase()}`}
              imagePath={item.imagePath.toLowerCase()}
              formType={formType}
              showPreview={true}
              // accept = {"image/*"}
              input={field}
              id={field.id}
              name={field.name}
              field={field}
              fieldState={fieldState}
              errors={errors}
            />)}
        />
      </div>
    )
  }

  let getFileName = async (file, item) => {
    let image = file;
    await setState({
      [item.name]: image
    });
  }

  let getDate = (i, item) => {
    return (
      <div>
        <Controller
          name={item.name}
          control={control}
          render={({ field, fieldState }) => (
            <DoDateField
              markReq={item.required}
              input={field}
              id={field.id}
              name={field.name}
              label={item.label}
              field={field}
              fieldState={fieldState}
              errors={errors}
              formType={props.formType}
              placeholder={item.placeholder ? item.placeholder : null}

            />)}
        />
      </div>
    )
  }

  let getTime = (i, item) => {
    return (
      <div>
        <Controller
          name={item.name}
          control={control}
          render={({ field, fieldState }) => (
            <DoDateField
              markReq={item.required}
              input={field}
              id={field.id}
              name={field.name}
              field={field}
              fieldState={fieldState}
              errors={errors}
              placeholder="time"
              timeOnly={true}
            />)}
        />
      </div>
    )
  }

  let getCkEditor = (i, item) => {
    let self = this;
    return (
      <div>
        <Controller
          name={item.name}
          control={control}
          render={({ field, fieldState }) => (
            <DoEditor
              field={field}
              errors={errors}
            />
          )}
        />
      </div>

      // <span>Testing</span>
    )
  }

  let getRadio = (i, item) => {
    return (
      <div key={i}>
        <Controller
          name={item.name}
          control={control}
          render={({ field, fieldState }) => (
            <DoRadioButtons
              markReq={item.required}
              options={item.options}
              input={field}
              id={field.id}
              name={field.name}
              field={field}
              label={item.label}
              fieldState={fieldState}
              errors={errors}
            // value={item.value}
            // defVal={item.value}
            />)}
        />
      </div>
    )
  }

  let getAutoComplete = (i, item) => {

    const isDisable = props.formType === 'add' && item.isAddFieldDisable ? true : props.formType === 'edit' && item.isEditFieldDisable ? true : false;
    // const onChange = (e) => {
    //   if (item.dependent && item.dependent.length > 0) {
    //     handleFnEnableControlsBasedOnValuea(e, item.dependent);
    //   }
    // }

    return (
      <div>
        <Controller
          name={item.name}
          control={control}
          render={({ field, fieldState }) => (
            <DoAutoCompleteField
              markReq={item.required}
              input={field}
              id={field.id}
              name={field.name}
              field={field}
              filterField={item.filterField}
              filterValue={item.filterValue}
              filterType={item.isNotEq}
              multiple={item.isMultiple}
              fieldState={fieldState}
              errors={errors}
              isDisable={isDisable}
              screen={props.type}

              searchApi={item.searchApi}
              searchField={item.searchField}
              allow={props.allowDuplicates}
              dependent={item.dependent ? item.dependent : null}
              filterFieldType={item.filterFieldType ? item.filterFieldType : null}
              onChange={(event) => handleAutoCompleteData(event, item.name)}
              placeholder={item.placeholder}
              label={item.label}
              item={item}
              getValues={getValues}
              call={item.call}
              isProductName={isProductName}
              handleFnEnableControlsBasedOnValue1={handleFnEnableControlsBasedOnValue1}
            />)}
        />
      </div>
    )
  }

  let getTextArea = (i, item) => {
    return (
      <div>
        <Controller
          name={item.name ? item.name : null}
          control={control}
          render={({ field, fieldState }) => (
            <DoTextareaField
              markReq={item.required}
              input={field}
              id={field.id}
              name={field.name}
              field={field}
              defVal={item.value}
              fieldState={fieldState}
              errors={errors}
              placeholder={item.placeholder ? item.placeholder : null}
            />)}
        />
      </div>
    )
  }

  let getButtonGroup = (i, item) => {
    return (
      <ButtonToolbar className='mt-0'>
        <ButtonGroup size="sm" >
          {item.options.map((button) => {
            return <Button style={{ paddingTop: 3, paddingBottom: 3 }}
              color="primary"
              outline size="sm"
              active={state[`is${button.label}ReOrder`]}
              onClick={() => handleReOrdering(button.value)}>{button.label}</Button>
          })
          }
        </ButtonGroup>
      </ButtonToolbar>
    )
  }


  let getDefault = (i, item) => {
    const isDisable = props.formType === 'add' && item.isAddFieldDisable ? true : props.formType === 'edit' && item.isEditFieldDisable ? true : false;

    let { t } = props;
    let onChange = (e, item) => {
      console.log("eeeeeee", e.target.value, e, item)
      setInputValue(e?.target?.value ? e.target.value : '')
      setDrawerField(item?.name ? item.name : '');
    }
    return (
      <div>
        <Controller
          name={item.name ? item.name : null}
          control={control}
          render={({ field, fieldState }) => (
            <DoInputField
              markReq={item.required}
              input={field}
              id={field.id}
              name={field.name}
              label={item.label}
              field={field}
              type={item.type ? item.type : "text"}
              fieldState={fieldState}
              errors={errors}
              isDisable={isDisable}
              defVal={item.value}
              placeholder={item.placeholder ? item.placeholder : null}
              onChange={(e) => onChange(e, item)}
            />)}
        />
      </div>
    )
  }
  /**@CheckBox Component */
  let getCheckbox = (i, item) => {
    let { t } = props;
    return (
      <div>
        <Controller
          name={item.name ? item.name : null}
          control={control}
          render={({ field, fieldState }) => {
            field.value = field.value ? field.value : false;
            return <DoCheckboxField
              markReq={item.required}
              input={field}
              id={field.id}
              name={field.name}
              label={item.label}
              field={field}
              type={item.type ? item.type : "text"}
              fieldState={fieldState}
              errors={errors}
              defaultChecked={false}
              placeholder={item.placeholder ? item.placeholder : null}
            />
          }}
        />
      </div>
    )
  }

  let getPassword = (i, item) => {
    let { t } = props;
    return (
      <div>
        <Controller
          name={item.name ? item.name : null}
          control={control}
          render={({ field, fieldState }) => (
            <DoPasswordField
              markReq={item.required}
              input={field}
              id={item.id ? item.id : null}
              name={field.name}
              field={field}
              fieldState={fieldState}
              errors={errors}
              screen={props.type}
              label={item.label}
              getValues={getValues}
              placeholder={item.placeholder ? item.placeholder : null}
            />)}
        />
      </div>
    )
  }
  /**not using For Now */
  let showPassword = (e) => {
    e.preventDefault();
    // setState(prevState => ({
    //   showPassword: !prevshowPassword
    // }));

  }



  let getButtonToolbar = () => {
    let { t } = props;
    return (
      <div className='d-flex'>
        <div className='col-12 px-0'>
          <div>
            {props.type === 'Storage Units' && props.tabType !== 'OR Suites' && optionsValue && elementName === "minInventoryByStateName" && element.name !== 'storageUnitCheckDay' ?
              <div className="form__form-group-field">
                <input
                  type="checkbox"
                  style={{
                    marginTop: '2px',
                    marginRight: '8px',
                    height: '30px',
                    width: '30px'
                  }}
                  checked={checkBoxChecked}
                  onChange={() => setCheckBoxChecked(!checkBoxChecked)}
                />
                <div style={{ paddingTop: '4px' }}>
                  <label className='align_checkBox'>
                    {configMessages.StorageOption}
                  </label>
                </div>
              </div>
              : null}
          </div>
          <span className='float-end'>
            <ButtonToolbar>
              {props.displayViewOfForm === 'modal' && formType === 'add' ?
                <Button className='btn custom-outline' color='primary' type="button" onClick={() => closeFormModal()}>
                  {t('Cancel') ? t('Cancel') : 'Cancel'}

                </Button>

                : formType !== 'add' ? <Button color='primary' className='btn custom-outline' type="button"
                  onClick={() => closeFormModal()}
                >
                  {t('Cancel') ? t('Cancel') : 'Cancel'}
                </Button> : formType === 'add' ?
                  <Button color='primary' className='btn custom-outline' type="button" onClick={() => closeFormModal()}>
                    {t('Cancel') ? t('Cancel') : 'Cancel'}
                  </Button>
                  : null}
              <Button className='btn custom-outline' color='primary' outlined type="submit" disabled={props.type === 'Storage Units' && props.tabType !== 'OR Suites' && !checkBoxChecked}>
                {/* <Button color='primary' outline type="submit"> */}
                {/* {formType && formType === 'add' ?
                  t('Save') ? t('Save') : 'Save'
                  : t('Update') ? t('Update') : 'Update'
                } */}
                {formType && formType === 'add' ?
                  (t('Save') ? t('Save') : 'Save')
                  :
                  (formType && formType === 'edit' ?
                    (t('Update') ? t('Update') : 'Update')
                    :
                    (t('Add To Order List') ? t('Add To Order List') : 'Add To Order List')
                  )
                }

              </Button>

            </ButtonToolbar>
          </span>
        </div>
      </div >
    )
  }
  let getEditButton = () => {
    let { t } = props;
    return (
      <div className='mx-1 d-flex justify-content-end my-2'>


        <a href={`/edit_${props.routeTo}/${userData && userData._id ? JSON.stringify(userData._id) : ''}`} >

          <Button color='primary' outline type="submit"
          >
            {
              t('Edit') ? t('Edit') : 'Edit'
            }
          </Button>

        </a>


      </div>
    )
  }
  let getModalHeader = () => {
    let { t } = props;
    return (
      <ModalHeader className="modal__header">
        {/* <button className="lnr lnr-cross modal__close-btn" type="button" onClick={closeFormModal} /> */}
        <p className="bold-text modal__title">
          {formType === 'edit' ? (t('Edit') || 'Edit') :
            formType === 'view' ? (t('View') || 'View') :
              (t('Add') || 'Add')
          }{' '}
          {props.type === 'Storage Units' || props.type === 'Expiration Dates' || props.type === 'Inventory Check Lists'
            || props.type === 'Min Inventory By Products' || props.type === 'MissingItems' || props.screen === 'wishlist' || props.type === 'Distributors' ?
            (props.modalHeaderName || props.type) :
            (props.type || null)
          }
        </p>
      </ModalHeader>

    )
  }

  let getDependentFields = (type, formType) => {
    return null;
  }

  let getMultiplePhotoUpload = (i, item) => {
    return (
      <div>
        <Field key={i}
          onRef={(ref) => (profileUploadRef = ref)}
          name={item.name ? item.name : null}
          component={RenderFileInputField}
          label={item.label}
          type='profile'
          id={'photo'}
          acceptType={'image/*'}
          url={apiCalls.LocationImagePath}
          getMulipleFileName={(file) => getMulipleFileName(file, item)}
          multiple={true}
        />
        <Scrollbar>
          <div className='mindivForMultipleupload' >
            {state[item.name] && state[item.name].length > 0 ? state[item.name].map((imagLocationItem, imagLocationIndex) => (<div className='col-md-2' style={{ padding: '20px' }}>
              <div key={imagLocationIndex} style={{ justifyContent: 'center' }}>
                <img
                  key={imagLocationIndex}
                  src={`${config.imgUrl}${item.imagePath}/${imagLocationItem['file']}`}
                  className='detailsImgStyle' />
                <FontAwesomeIcon icon='times-circle' className='timesCircleIcon'
                  onClick={() => removeMultipleUploadedImages(imagLocationIndex, item)}
                />
              </div>
            </div>)) : null
            }
          </div>
        </Scrollbar>
      </div>
    )
  }
  let getDeleteRowModal = () => {
    return (
      <DeleteRowModal
        openDeleteModal={openDeleteModal}
        closeDeleteModal={closeDeleteModal}
        selectActions={selectActions}
        deleteSelectedRow={handleActions}
        confirmModalText={confirmModalText}
      />
    )
  }
  // Store selected Images in state
  let getMulipleFileName = async (file, item) => {
    let multipleLocationImage = state[item.name];
    multipleLocationImage.push({ "file": file })
    await setState({
      [item.name]: multipleLocationImage
    });
  }

  // Remove selected image from state
  let removeMultipleUploadedImages = async (imagLocationIndex, item) => {
    let multipleLocationImage = state[item.name];
    multipleLocationImage.splice(imagLocationIndex, 1);
    await setState({
      [item.name]: multipleLocationImage
    })
  }

  let getItemField = (item, i) => {
    let { t } = props;
    return (
      <div id={item.name} key={props.type + i}
        className={(item.isAddFormHidden && formType === 'add') ? 'd-none' :
          item.name == "levels" && props.type == "Roles" && !showorHideLevelsField ? 'd-none' :
            props.type == "Min Inventory By Products" && item.name == "volume" && (!showVolumeFields) ? 'd-none' :
              (item.isEditFormHidden && formType === 'edit') ? 'd-none' : (item.type === 'ckeditor' || item.type === 'permission' || item.type === 'multipleprofile' ? 'col-sm-12' : (
                (item.type === 'autoComplete' && item.name === 'assignedTo') ||
                  item.name === 'closebutton' ? 'col-sm-4' : displayViewOfForm == "sideForm" ? "col-sm-12" : "col-sm-6"))}
      >
        <div className="form__form-group mb-3 ml-1" >
          {/* <label className="form__form-group-label">
            {t(item.label)}
          </label> */}
          {item.type === 'dropDown' && item.isMultiSelect ?
            getDropdownMultiselect(i, item)
            : item.type === 'dropDown' ?
              getDropdown(i, item)
              : item.name === 'closebutton' ?
                getCloseButton(i, item)
                : item.type === 'profile' ?
                  getProfile(i, item)
                  : item.type === 'date' ?
                    getDate(i, item)
                    : item.type === 'time' ?
                      getTime(i, item)
                      : item.type === 'ckeditor' ?
                        getCkEditor(i, item)
                        : item.type === 'empty' ?
                          <div> </div>
                          : item.type === 'radio' ?
                            getRadio(i, item)
                            : item.type === 'permission' ?
                              // getRolePermissions()
                              getScreenPermissions()
                              : item.type === 'autoComplete' ?
                                getAutoComplete(i, item)
                                : item.type === 'relateAutoComplete' ?
                                  getAutoComplete(i, item)
                                  : item.type === 'textarea' ?
                                    getTextArea(i, item)
                                    : item.type === 'buttonGroup' ?
                                      item.options && item.options.length > 0 ? getButtonGroup(i, item) : null
                                      : item.type === 'multipleprofile' ?
                                        getMultiplePhotoUpload(i, item) :
                                        item.type == "password" ?
                                          getPassword(i, item)
                                          : item.type == "encryptedField" ?
                                            getPassword(i, item)
                                            /**@CheckBox */
                                            : item.type == 'checkbox' ?
                                              getCheckbox(i, item)
                                              : getDefault(i, item)

          }
        </div>
      </div>

    )
  }

  let getFields = () => {
    let allFields = <div></div>
    let item;
    for (let i = 0; i < formFields.length; i++) {
      item = formFields[i];
      if (item.show === false || (item.isAddFormHidden === true && formType === 'add') || (item.isEditFormHidden === true && formType === 'edit')) {

      } else {
        allFields = <>{allFields}{getItemField(item, i)}</>
      }
    }
    return allFields;
  }
  let setSlno = async (actionType) => {
    let { totalRecords, first, rows, onPageChange, isWeb } = props;
    let sLno = slno;
    if (actionType === 'back') {
      if (sLno !== 0) {
        setSslno((slno) => slno - 1);
        handleNextAndBackActions(actionType)
      } else {
        setSslno(slno + 1);
      }
    } else if (actionType === 'next') {
      let total = '';
      total = props.allUsersData.length;
      if (sLno !== total) {
        setSslno((slno) => slno + 1);
        setColored(true)
        handleNextAndBackActions(actionType);
      } else {
        setSslno(slno);
      }
    }
  }

  let getPaginationWithIcons = () => {
    let { totalRecords, first, rows, onPageChange, isWeb } = props;
    // let { slno } = state;
    return (
      <div className='d-flex h-100'>
        <div className='pagenumber-indicator'>
          {slno === 0 ? 1 : slno + 1} / {props.allUsersData.length}&nbsp;&nbsp;
        </div>
        <div>
          <ButtonGroup className='mb-0'>
            <Button color="primary"
              outline
              disabled={slno === 0 ? true : false}
              size="sm"
              style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
              className="p-1 ml-auto mt-1 mb-0"
              onClick={() => setSlno('back')}
            >
              <FontAwesomeIcon
                icon='chevron-left'
                className='pl-1' size='lg'
                data-toggle="tool-tip" title="List"

              />
            </Button>
            <Button color="primary"
              outline
              disabled={slno === props.allUsersData.length - 1 ? true : false}
              size="sm"
              style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
              className="p-1 ml-auto mt-1 mb-0"
              onClick={() => setSlno('next')}
            >
              <FontAwesomeIcon
                icon='chevron-right'
                className='pl-1' size='lg'
                data-toggle="tool-tip" title="List"

              />
            </Button>
          </ButtonGroup>
        </div>

      </div>
    )
  }

  //getModalBody
  let getModalBody = () => {
    return (
      <ModalBody className="modal__body mb-0 pt-3">
        <Card className='pb-0 cardForListMargin'>
          <CardBody className='tableCardBody'>
            <form onSubmit={handleSubmit(submit)} autoComplete={'off'}>
              <Loader loader={isLoading} />
              <div className="row form" >
                {getFields()}
                {sessionExpiryModal ?
                  <SessionExpiryModal
                    SOpen={sessionExpiryModal}
                  />
                  : null
                }
                {props.getDependentFields && props.getDependentFields(props.type, props.formType)}
              </div>
              {getButtonToolbar()}
            </form>
          </CardBody>
        </Card>
      </ModalBody>
    )
  }

  const onErrors = (data) => {
  }

  //getScreenBody
  let getScreenBody = () => {
    return (
      <form onSubmit={handleSubmit(submit, onErrors)} autoComplete={'off'}>
        <Loader loader={isLoading} />
        <div className={props.displayViewOfForm == 'sideForm' ? "col-12" : props.formType === 'add' ? "row form pt-3" : "row form "}>
          {getFields()}
          {sessionExpiryModal ?
            <SessionExpiryModal
              SOpen={sessionExpiryModal}
            />
            : null
          }
          {props.getDependentFields && props.getDependentFields(props.type, props.formType)}
        </div>
        {getButtonToolbar()}
      </form>
    )
  }

  //getModalView
  let getModalView = () => {
    let { handleSubmit } = props;
    let modalClass = classNames({
      'modal-dialog--colored': colored,
      'modal-dialog--header': header,
    });
    return (

      <Dialog
        visible={props.openFormModal}
        onHide={closeFormModal}
        draggable={false}
        style={{ width: '50vw' }}
        header={getModalHeader()}
        closeOnEscape={false}
      >
        {/* Modal is for Reference  */}
        {/* <Modal
         isOpen={props.openFormModal}
         className={`modal-dialog-centered modal-dialog--primary  ${modalClass}`}
       > */}

        {/* {getModalHeader()} */}
        {formType === 'view' ?
          <ModalBody className="modal__body mb-0 pt-2">
            <Card className='pb-0 cardForListMargin'>
              <CardBody className='tableCardBody'>
                {getViewBody()}
                {getEditButton()}
              </CardBody>
            </Card>
          </ModalBody> : getModalBody(handleSubmit)}
        {/* </Modal> */}
      </Dialog>
    )
  }
  let cancelUserPwdResetModal = async () => {
    setOpenUserPasswordResetModal(false);
    setActions('')
  }
  let getUserPasswordResetModal = () => {
    return (
      <UserPasswordResetModal
        openUserPasswordResetModal={openUserPasswordResetModal}
        userId={editRowDataID}
        cancelReset={cancelUserPwdResetModal}
        entityType={props.entityType}
      />
    )
  }

  let OptionsModal = () => {
    return (
      <OptionsModal
        openOptionModal={openOptionModal}
      />
    )
  }
  let getHeader = () => {
    let { t } = props;
    if (props.displayViewOfForm != "sideForm") {
      return (
        <div className='d-flex'>
          <div className='col-12 px-0 pb-1'>
            <span className='float-left pt-2'>
              <h4 style={{ textTransform: 'capitalize' }}><b>
                <Link
                  // currently not using 
                  // to={props.routeTo.startsWith("/") ? props.routeTo : `/${props.routeTo}`} 
                  onClick={closeFormModal}>
                  {props.type}
                </Link>
                {formType !== 'add' && displayBreadCrumbValue ? ` / ${displayBreadCrumbValue} ` : null}
              </b> </h4>
            </span>
          </div>
        </div>
      )
    }
  }
  let submitActionsData = async (method, url) => {
    return fetchMethodRequest(method, url)
      .then(async (response) => {
        let sessionexpired = localStorage.getItem('sessionexpired')
        if (sessionexpired == "true") {
          setSessionExpiryModal(true);
        }
        setOpenDeleteModal(false);
        setActions('');
        if (response && response.respCode) {
          showToasterMessage(response.respMessage, 'success');
          await props.getDataFromServer(props.filterCriteria);
          await props.closeFormModal();
          await handleNextAndBackActions();
          // props.closeFormModal('save');
        } else if (response && response.errorMessage) {
          showToasterMessage(response.errorMessage, 'error');
        }
      }).catch((err) => {
        return err;
      });
  }

  let handleActions = async () => {
    let apiUrl = props.apiUrl, url = '', method = '';
    if (confirmType === 'Delete') {
      method = 'DELETE';
      url = `${apiUrl}/${editRowDataID}`;
      submitActionsData(method, url)
    }
    if (confirmType === 'Block') {
      method = 'PUT';
      url = `${apiUrl}/block/${editRowDataID}?block=true`;
      submitActionsData(method, url)
    }
    if (confirmType === 'ResetPassword') {
      setOpenUserPasswordResetModal(true);
      setOpenDeleteModal(false);
    }

  }

  // conformation for delete item
  let deleteConfirmAction = async (rowData, selectActions) => {
    setOpenDeleteModal(true);
    setSelectActions(selectActions);
  }
  let confirmActionType = async (type) => {
    if (type === 'Delete') {
      await setConfirmType(type);
      await setConfirmModalText('Are you sure want to Delete')
      deleteConfirmAction()
    } else if (type === 'Block') {
      await setConfirmType(type);
      await setConfirmModalText('Are you sure want to Block')

      deleteConfirmAction()
    } else if (type === 'ResetPassword') {
      await setState({
        confirmType: type,
        confirmModalText: 'Are you sure want to Reset Password',
        openUserPasswordResetModal: true,
        openDeleteModal: false
      })
      await setConfirmType(type);
      await setConfirmModalText('Are you sure want to Reset Password');
      await setOpenUserPasswordResetModal(true);
      await setOpenDeleteModal(false);

      // deleteConfirmAction()
    }
  }

  //onActionsChange
  let onActionsChange = async (event, type) => {
    if (type == 'dropdownFilter') {
      await setState({
        [event.target.name]: event.target.value,
      })
      confirmActionType(event.target.value)
    }
  }
  let getViewBody = () => {
    let { t } = props;
    return (<div>

      <div className='row'>
        <div className='col-sm-4 pb-2'>
        </div>
        <div className='col-sm-8 text-lg-right'>
          {originalTableFields && originalTableFields.length > 0 ? originalTableFields.map((item, index) => {
            return item.fieldType === 'Badge' && item.options && item.options.length > 0 ? item.options.map((optionsItem, optionsIndex) => {
              return (
                <Button key={optionsIndex} color='primary' onClick={() => saveDataToServer({ "status": optionsItem.value })} disabled={userStatus === optionsItem.value ? true : false}>{optionsItem.value}</Button>
              )
            }) : null
          }) : null}
        </div>
      </div>
      {rowData && rowData.length > 0 ?
        <div className="row form" >
          {rowData.map((item, i) => {
            return (
              item.value !== "" && item.value !== null && item.label !== 'SNo' ?
                <div className={displayViewOfForm == "sideForm" ? 'col-12' : 'col-sm-6 '} key={i}>
                  <div className="row" style={{ margin: "auto" }}>
                    <div
                      className="col-5 paddingRowDataCol"
                    >
                      <span className='fontWeight' style={{ fontWeight: "bold" }}>
                        {item.label}
                      </span>
                    </div>

                    <div className="col-7 paddingOfRowData"
                    >
                      <span>{item.fieldType && item.fieldType == 'dropDown' ?
                        getBadgeData(item, item.value) : item.value
                      }</span>
                    </div>
                  </div>
                </div> : null
            );
          })}
        </div>
        : null
      }
    </div>

    )
  }
  let getBadgeData = (element, value) => {
    let mcolor = props.getColorFromOptions(element.options, value);
    return (<Badge color={mcolor} pill >{value}</Badge>)
  }
  //sorting fields
  let sortChange = (event) => {
    setSelectedRows('')
    let sortCount = sortCount;
    if (event && event['sortField']) {
      sortCount = sortCount == 0 ? sortCount + 1 : 0;
      let sortField = event['sortField'];
      let filterCriteria = filterCriteria;
      filterCriteria['direction'] = sortCount == 0 ? "desc" : 'asc';
      filterCriteria['sortfield'] = sortField;
      setSortCount(sortCount);
      setFilterCriteria(filterCriteria);
      getActivities();
    }
  }
  let getColumns = (e, d) => {
    let { t } = props;
    let tablefieldsToShow = getTableFields();
    if (tablefieldsToShow && tablefieldsToShow.length > 0) {
      return tablefieldsToShow.map((item, i) => {
        let column = (item.show &&
          <Column key={item.field + i}
            style={{
              maxwidth: item.width,
              padding: 2,
            }}
            bodyStyle={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textAlign: item.field == 'status' || item.field == 'role' ? 'center' : item.textAlign
            }}
            field={item.field}
            header={t(item.header)}
            body={self.changeFieldValues}
            headerStyle={{
              padding: 4, fontWeight: 500, width: item.width, fontSize: 13,
              color: config.whiteColor, backgroundColor: config.templateColor
            }}
            filter={false}
            sortable={item.sortable ? true : false}
            filterPlaceholder={item.placeholder ? item.placeholder : 'search'}

            selectionMode={item.selectionMode}
          />
        )
        return column;
      })
    }
  }
  let getDataTable = () => {
    let dt;

    return (
      <DataTable
        ref={(el) => dt = el}
        value={activitiesData}
        totalRecords={totalRecordsLength}
        paginator={false}
        lazy={true}
        resizableColumns={true}
        columnResizeMode="expand"
        onSort={sortChange}
        globalFilter={props.globalFilter}
        onFilter={props.onFilterChange}
        scrollable={true}
        selection={false}
        scrollHeight='1000px'
        // style={{ marginTop: 0 }}
        emptyMessage={configMessages.noRecords}
        sortMode="single"
        // sortField="fname"
        // sortOrder={-1}
        // selectionMode={'multiple'}
        metaKeySelection={false}
        loading={isLoading}
        style={activitiesData && activitiesData.length == 0 ?
          { textAlign: 'center' }
          : null}
      >
        {getColumns()}
      </DataTable>



    )
  }
  let getActivitiesHeader = () => {
    let { t } = props
    return (
      <div className='col-12  pb-1'>
        <span className='float-left pt-2'>
          <h4 style={{ textTransform: 'capitalize' }}><b>

            <Link
              // Currently not using
              // to={props.routeTo.startsWith("/") ? props.routeTo : `/activities`} 
              onClick={closeFormModal}>
              {t('Activities')}
            </Link>
            {formType !== 'add' && displayBreadCrumbValue ? ` / ${displayBreadCrumbValue} ` : null}
          </b> </h4>
        </span>
        <span className='float-right pt-2'>
          {/* {getPaginator()} */}
        </span>
      </div>
    )
  }
  //getScreenView
  let getScreenView = () => {
    let { handleSubmit } = props;
    return (
      <div>

        {props.openFormModal ? <div
        >
          <div className='d-flex justify-content-between pb-2 mx-1'>

            <div >
              {getHeader()}
            </div>
            <div >
              {formType !== 'add' && getPaginationWithIcons()}
            </div>

          </div>
          {formType !== 'view' ? getScreenBody(handleSubmit) : getViewBody()}
          {formType === 'view' && props.editRequired ? getEditButton() : null}
          {formType !== 'add' && formType !== 'edit' ?

            < div >
              <div className='row'>
                {getActivitiesHeader()}
              </div>
              <div className='row'>
                <div className='col-sm-12'>
                  {getDataTable()}
                </div>
              </div>
            </div> : null
          }
        </div> : null
        }
      </div>
    );
  }




  let { displayViewOfForm } = props;
  return (
    display && <div>
      {displayViewOfForm === 'modal' ?
        getModalView() : displayViewOfForm === 'screen' || displayViewOfForm == 'sideForm' ?
          getScreenView() : null
      }
      {openDeleteModal ? getDeleteRowModal() : null}
      {openUserPasswordResetModal ? getUserPasswordResetModal() : null}
      {openOptionModal ? OptionsModal() : null}
    </div>
  );

}
/**not using For Now */
// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
// FormModal = reduxForm({
//   form: "Common Form Modal", // a unique identifier for this form
//   validate,
//   enableReinitialize: true,
// })(FormModal);

// // You have to connect() to any reducers that you wish to connect to yourself
// FormModal = connect(
//   state => ({
//     initialValues: commonData.data // pull initial values from account reducer
//   }),
//   { load: loadAccount } // bind account loading action creator
// )(FormModal);

export default withTranslation('common')(FormModal);