import React from "react";
import configImages from "../../config/configImages";
import RegisterForm from "./components/Register";
import config from '../../config/config';
// import LogoUI from "../LogoUI/logoUI"
const Register = () => (
  <div>
    {config.selectedLoginScreenName && config.selectedLoginScreenName == "1" ?
      <div className='common-login-container col-12'>
        <div className='login1 registercard-width' >

          <div className='card-body'>
            <div className='text-center w-100 pb-4 '>
              <b class="fs-4">Welcome to</b>
              <img src={configImages.loginImage} className='login-logo'></img>
            </div>
            <RegisterForm onSubmit />
          </div>
        </div>
      </div>
      : config.selectedLoginScreenName && config.selectedLoginScreenName == "2" ?
        <div className='common-login-container'>
          <div className='login-2 register-2 card'>
            <div className='login-img'>
              <img src={configImages.loginSideImage} alt="" className='h-100' />
            </div>
            <div className='card login2-form'>
              <div className='card-body'>
                <div className='text-center w-100 pb-4 '>
                  <img src={configImages.loginImage} className='login-logo'></img>
                </div>
                <RegisterForm onSubmit />
              </div>
            </div>
          </div>
        </div>
        : config.selectedLoginScreenName && config.selectedLoginScreenName == "3" ?
          <div className='common-login-container'>
            <div className='login-2 register-2 card'>

              <div className='card login2-form'>
                <div className='card-body'>
                  <div className='text-center w-100 pb-4 '>
                    <img src={configImages.loginImage} className='login-logo'></img>
                  </div>
                  <RegisterForm onSubmit />
                </div>
              </div>
              <div className='login-img'>
                <img src={configImages.loginSideImage} alt="" className='h-100' />
              </div>
            </div>
          </div>
          : config.selectedLoginScreenName && config.selectedLoginScreenName == "4" ?
            <div className='common-login-container'>
              <div className='login-4 '>
                <div className='login-side-logo'>
                  <img src={configImages.loginImage} alt="" className='h-100' />
                </div>
                <div className='border-left login-form'>
                  <div className='p-3'>
                    <RegisterForm onSubmit />
                  </div>
                </div>
              </div>
            </div>

            : null}
  </div >
);

export default Register;
