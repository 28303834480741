import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import SupplyCabinets from './components/SupplyCabinets';

const supplyCabinets = (props,{ t }) => (
  <Container>
    <SupplyCabinets {...props} />
  </Container>
);

supplyCabinets.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(supplyCabinets);
