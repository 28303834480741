import React, { PureComponent, useEffect, useState } from 'react';
import { Button, CardBody, Modal, ModalHeader, ModalBody } from "reactstrap";
import { Link, Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { load as loadAccount } from '../../../redux/reducers/commonReducer';
// import {settingsAction} from '../../../../redux/actions/settingsAction'
import { settings } from '../../../redux/actions/settingsAction'
import PropTypes from 'prop-types';

import renderCheckBoxField from '../../../shared/components/form/CheckBox';

// Validate input
// import validate from '../../Validations/validate';
import validate from '../../Validations/validate';

// Input field
import DefaultInput from '../../../shared/components/form/DefaultInput';

import DoInputField from '../../Form/Fields/DoInputField';
import DoDateField from '../../Form/Fields/DoDateField';
import DoSelectField from '../../Form/Fields/DoSelectField';
import DoPasswordField from '../../Form/Fields/DoPasswordField';


// Config
import configMessage from '../../../config/configMessages';
import fetchRequest from '../../../config/service';
import apiCalls from '../../../config/apiCalls';
import config from '../../../config/config';
import GoogleLogin from 'react-google-login'
// Toaster message
import showToasterMessage from '../../UI/ToasterMessage/toasterMessage';
import jwt_decode from "jwt-decode"
import ReCAPTCHA from "react-google-recaptcha";
import {
  browserName,
  osName, osVersion, deviceType
} from "react-device-detect";
// Loader
import Loader from '../../App/Loader';
import { useForm, Controller } from 'react-hook-form';
import PasswordField from '@/shared/components/form/password';

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
const publicIp = require('public-ip');
const options = require('../../../translations/states/options.json');
let newpassval = value => (value ? config.passwordRegex.test(value) ? undefined : 'Not a secure paswword, hint:User1234$' : configMessage.fillField)
const newpassvalLength = value => (value ? value.length >= 8 ? undefined : 'Password length should be atleast 8' : configMessage.fillField)

const required = value => (value || typeof value === 'string' ? undefined : configMessage.fillField)
const normalizePhone = (value) => {
  if (!value) {
    return value
  }
  const onlyNums = value.replace(/[^\d]/g, '')
  if (onlyNums.length <= 3) {
    return onlyNums
  }
  if (onlyNums.length <= 7) {
    return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3)}`
  }
  return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3, 6)}-${onlyNums.slice(6, 10)}`
}

// const normalizenumber = (value) => {
//   if (!value) {
//     return value;
//   }
//   const onlyNums = value.replace(/\D/g, "");
//   return `${onlyNums}`;
// };
const Register = (props) => {
  const [deviceInfo, setDeviceInfo] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [isLoginDisabled, setIsLoginDisabled] = useState(config.displayRecaptcha ? true : false);
  const [loginRememberInfo, setLoginRememberInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isRobot, setIsRobot] = useState(false);
  const [isLoginSuccess, setIsLoginSuccess] = useState(false);
  const [isNotChecked, setIsNotChecked] = useState(false);
  const [remember_me, setRemember_me] = useState(false);
  const [loginRole, setLoginRole] = useState('');
  const [captchaValue, setCaptchaValue] = useState(null);
  const [settingsData, setSettingsData] = useState({});
  const [isGoogleLoginSuccess, setIsGoogleLoginSuccess] = useState('');
  const [userDetails, setUserDetails] = useState({});
  const [displayDetailsModal, setDisplayDetailsModal] = useState(false);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [isRegisterButtonDisabled, setIsRegisterButtonDisabled] = useState(true);

  const [amount, setAmount] = useState('');
  const [labels, setLabels] = useState([]);



  const formFields = [
    {
      required: true,
      value: "",
      type: "text",
      name: "name",
      label: "Company Name",
      id: "companyName",
      placeholder: "Company Name",
      mandatory: true
    },
    {
      required: true,
      value: "",
      type: "text",
      name: "email",
      label: "Company Email",
      id: "email",
      placeholder: "Company Email",
      mandatory: true

    },
    // {
    //   value: "",
    //   type: "text",
    //   name: "phone",
    //   label: "Company Phone Number",
    //   id: "maxCarts",
    //   placeholder: "Company Phone",
    //   mandatory: false

    // },
    // {
    //   value: "",
    //   type: "text",
    //   name: "contactPerconName",
    //   label: "Contact Person Name",
    //   id: "email",
    //   placeholder: "Contact Person Name",
    //   mandatory: false
    // },
    // {
    //   value: "",
    //   type: "text",
    //   name: "contactPersonEmail",
    //   label: "Contact Person Email",
    //   id: "Contact Person Email",
    //   placeholder: "Email@yopmail.com",
    //   mandatory: false

    // },
    // {
    //   value: "",
    //   type: "text",
    //   name: "contactPersonPhone",
    //   label: "Contact Person Phone Number",
    //   id: "email",
    //   placeholder: "Contact Person Phone",
    //   mandatory: false
    // },
    // {
    //   value: "",
    //   type: "text",
    //   name: "address",
    //   label: "Company Address",
    //   id: "address",
    //   placeholder: "Address",
    //   mandatory: false

    // },
    // {
    //   value: "",
    //   type: "text",
    //   name: "city",
    //   label: "City",
    //   id: "city",
    //   placeholder: "City",
    //   mandatory: false

    // },
    // {
    //   value: "",
    //   type: "text",
    //   name: "zip",
    //   label: "Zip",
    //   id: "zip",
    //   placeholder: "Zip",
    //   mandatory: false

    // },
    {
      value: "",
      type: "dropDown",
      name: "state",
      label: "State",
      id: "state",
      placeholder: "State",
      mandatory: false,
      options: options
    },
    {
      value: "",
      type: "text",
      name: "maxCarts",
      label: "Maximum Number of carts",
      id: "maxCarts",
      placeholder: "Maximum Number of carts",
      mandatory: false

    },

    {
      required: true,
      value: "",
      type: "password",
      name: "newPassword",
      label: "Create Password",
      id: "newPassword",
      placeholder: "New Password",
      mandatory: true

    },
    {
      required: true,
      value: "",
      type: "password",
      name: "confirmPassword",
      label: "Confirm Password",
      id: "confirmPassword",
      placeholder: "Confirm Password",
      mandatory: true
    },
    // {
    //   value: "",
    //   type: "dropDown",
    //   name: "selectPlanType",
    //   label: "Select Plan Type",
    //   id: "Select Plan Type",
    //   placeholder: "Select Plan Type",
    //   mandatory: false,
    // },
  ]

  useEffect(() => {
    getSettingsData();
    setDeviceDetails();
  }, []);

  const setDeviceDetails = async () => {
    await setDeviceInfo({
      browserName: browserName,
      osName: osName,
      osVersion: osVersion,
      deviceType: deviceType
    });
  }

  const schema = yup.object().shape({
    name: yup.string().required(),
    // phone: yup.string().required(),
    email: yup.string().email().required(),
    newPassword: yup
      .string()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,32}$/,
        'Password must contain at least one uppercase letter, one lowercase letter, one special character, one number, and be 8 characters long'
      )
      .required(),
    confirmPassword: yup
      .string()
      .min(8)
      .max(32)
      .oneOf([yup.ref('newPassword'), null], 'Confirm Password and Create Password are not same')
      .required(),
  });


  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleLoginUser = async (values) => {
    let userBody = {
      email: values.email,
      password: values.password,
      entityType: config.entityType,
      deviceInfo: deviceInfo
    };
    localStorage.setItem('loginBody', JSON.stringify(userBody));
    if (captchaValue || !config.displayRecaptcha) {
      return fetchRequest('POST', apiCalls.loginUser, userBody)
        .then(async (response) => {
          if (response && response.respCode && response.respCode == 200) {
            // this.setState({
            //   isLoading: true
            // });cd 
            setIsLoading(true);
            let userDetails = response.details;
            if (userDetails && userDetails._id) {
              if (response.accessToken) {
                let tokenInfo = {
                  accessToken: response.accessToken,
                  refreshToken: response.refreshToken,
                  tokenExpires: response.tokenExpires,
                };
                userDetails = { ...userDetails, ...tokenInfo };
                // save user credentials in storage
                localStorage.setItem('loginCredentials', JSON.stringify(userDetails));
                localStorage.removeItem('sessionexpired');
                if (userDetails && userDetails.role && userDetails.role == 'Teacher') {
                  setLoginRole(userDetails.role);
                }
              }
              if (userDetails && userDetails.rolePermissions) {
                let permissions = userDetails.rolePermissions;
                // save user permissions in storage
                localStorage.setItem('rolePermissions', JSON.stringify(permissions));
              }
            }
            showToasterMessage(response.respMessage, 'success');
            setIsLoginSuccess(true);
            if (remember_me) {
              var data = {
                email: values.email,
                password: values.password,
                remember_me: this.state.remember_me
              }
              if (loginRememberInfo && loginRememberInfo.email &&
                loginRememberInfo.email != values.email) {
                localStorage.setItem('loginRememberInfo', JSON.stringify(data));
              } else if (!loginRememberInfo) {
                localStorage.setItem('loginRememberInfo', JSON.stringify(data));
              }
            } else {
              localStorage.removeItem('loginRememberInfo');
            };
          } else if (response && response.errorMessage) {
            setTimeout(() => {
              setIsLoginDisabled(false);
              setIsLoading(false);
            }, 250);
            showToasterMessage(response.errorMessage, 'error');
          }
          // settingsAction
        }).catch((err) => {
          setIsLoginDisabled(false);
          setIsLoading(false);
        });
    }
  }



  const submit = (values) => {
    values.status = "Active"
    values.role = "Admin";
    values.deviceInfo = deviceInfo;
    return fetchRequest('POST', "companyUsers/register", values)
      .then(async (response) => {
        if (response && response.respCode && response.respCode == 204) {
          handleLoginUser({ email: values.email, password: values.newPassword })

        } else if (response && response.errorMessage) {
          showToasterMessage(response.errorMessage, 'error');
        }

      })

  }

  const onChange = (value) => {
    if (config.displayRecaptcha) {

      setCaptchaValue(value ? value : null);
      setIsLoginDisabled(value ? false : true);
    }
  }

  const getSettingsData = () => {
    let settings;
    fetchRequest('GET', `settings`).then(async (response) => {
      if (response && response.respCode) {
        settings = response.settings[0]
        const paymentLabel = response.settings[0].paymentTypes.map(item => {
          // Splitting the label text at a certain character limit
          const labelParts = item.label.match(/.{1,45}\b/g);
          return {
            ...item,
            label: (
              <>
                {labelParts.map((part, index) => (
                  <p key={index}>{part}</p>
                ))}
                <p>{configMessage.selectPlanType}</p>
              </>
            ),
            value: ""
          };
        });
        setLabels(paymentLabel);
        // settings["GoogleClientId"] = settings["Admin"].GoogleClientId;
        settings["GooglesecretKey"] = settings["Admin"].GooglesecretKey;
        setSettingsData(settings);
      }
    })
    return settingsData;
  }

  const loadGoogleComponent = (self) => {
    function handleCredentialResponse(response) {
      var data = jwt_decode(response.credential);
      if (data.email && data.name && config.displayGoogleLogin) {
        return fetchRequest('POST', 'auth/socialLogin', { name: data.name, email: data.email, role: "Admin", entityType: config.entityType, deviceInfo: self.state.deviceInfo })
          .then(async (response) => {
            if (response && response.respCode && response.respCode == 200) {
              self.setState({
                isLoading: true
              });
              let userDetails = response.details;
              if (userDetails && userDetails._id) {
                if (response.accessToken) {
                  let tokenInfo = {
                    accessToken: response.accessToken,
                    refreshToken: response.refreshToken,
                    tokenExpires: response.tokenExpires,
                  };
                  userDetails = { ...userDetails, ...tokenInfo };

                  localStorage.setItem('loginCredentials', JSON.stringify(userDetails));
                }
                if (userDetails && userDetails.rolePermissions) {
                  let permissions = userDetails.rolePermissions;
                  localStorage.setItem('rolePermissions', JSON.stringify(permissions));
                }
              }

              showToasterMessage(response.respMessage, 'success');
              if (userDetails.firstTimeLogin) {
                self.setState({
                  // isLoginSuccess: true,
                  displayDetailsModal: true,
                  isLoading: false,
                  userDetails: userDetails
                })
                if (self.props.load) {
                  setTimeout(() => {
                    self.props.load(self.state.userDetails)
                  }, 300);
                }

              } else {
                self.setState({
                  isLoginSuccess: true,
                  isGoogleLoginSuccess: true,
                })
              }
            } else if (response && response.errorMessage) {
              setTimeout(() => {
                self.setState({
                  isLoginDisabled: false,
                  isLoading: false
                })
              }, 250);
              showToasterMessage(response.errorMessage, 'error');
            }
            // settingsAction
          }).catch((err) => {
            self.setState({
              isLoginDisabled: false,
              isLoading: false
            });
          });
      }
    }
    if (config.displayGoogleLogin) {
      if (self.state.settingsData && self.state.settingsData.GoogleClientId) {
        let GoogleClientId = self.state.settingsData && self.state.settingsData.GoogleClientId ? self.state.settingsData.GoogleClientId : null
        google.accounts.id.initialize({
          client_id: GoogleClientId,
          callback: handleCredentialResponse
        });
        google.accounts.id.renderButton(
          document.getElementById("loginButton"),
          { theme: "outline", size: "large", width: document.getElementById('googleButton').clientWidth }  // customization attributes
        );
      }
    }
    return true
  }


  const cancelReset = async type => {

    setIsLoginSuccess(false);
    setIsGoogleLoginSuccess(false);
    setDisplayDetailsModal(false);
    localStorage.removeItem("rolePermissions");
    localStorage.removeItem("loginCredentials");
    reset();
  }

  //link to open the terms and condition 
  const openTermsConditions = () => {
    window.open('https://inmedtory.com/terms/', '_blank');
  };

  //link to open the privacy and policy 
  const openPrivacyPolicy = () => {
    window.open('https://inmedtory.com/privacy-policy/', '_blank');
  };

  //handleling the check box status 
  const handleCheckboxChange = () => {
    setIsCheckboxChecked((prevIsChecked) => !prevIsChecked);
    setIsRegisterButtonDisabled((prevIsChecked) => !prevIsChecked);
  };
  const handleModalSubmit = async formValues => {
    if (formValues) {
      delete formValues.email;
      delete formValues.password;
      formValues["firstTimeLogin"] = false
      let Url;
      if (localStorage.getItem('loginCredentials')) {
        let user = JSON.parse(localStorage.getItem('loginCredentials'));
        Url = `${apiCalls["Employees"]}/${user._id}`;
      }
      return fetchRequest('PUT', Url, formValues)
        .then(async (response) => {
          if (response && response.respCode && response.respCode === 205) {
            showToasterMessage(response.respMessage, 'success');

            setIsLoginSuccess(false);
            setIsGoogleLoginSuccess(true);
            setDisplayDetailsModal(false);

          } else if (response && response.errorMessage) {

            setIsLoginSuccess(false);
            setIsGoogleLoginSuccess(false);
            setDisplayDetailsModal(false);
            showToasterMessage(response.errorMessage, 'error');
          }
        }).catch((err) => {

          setIsLoginSuccess(false);
          setIsGoogleLoginSuccess(false);
          setDisplayDetailsModal(false);
          return err;
        });

    } else {

      setIsLoginSuccess(false);
      setIsGoogleLoginSuccess(false);
      setDisplayDetailsModal(false);
      return;
    }

  }
  // render() {
  //   const { handleSubmit } = this.props;
  //   const { showPassword, formFields } = this.state;

  return (
    <span>
      {displayDetailsModal ?
        <Modal
          isOpen={displayDetailsModal}
          toggle={cancelReset}
          centered
          className={`modal-dialog modal-dialog-centered modal-dialog--primary  modal-dialog--header`}
        >
          <ModalHeader className="modal__header">
            <button
              className="lnr lnr-cross modal__close-btn"
              type="button"
              onClick={cancelReset}
            />
            <p className="bold-text  modal__title"> {"Details"} </p>
          </ModalHeader>
          <ModalBody className="p-2">
            <Loader loader={isLoading} />

            <form className="form" onSubmit={handleSubmit(handleModalSubmit)}>
              <div className="row mx-1 mt-3 " style={{ width: '100%' }}>

                {
                  formFields && formFields.length && formFields.length > 0 ?
                    formFields.map((item, index) => {
                      return (
                        <div className="col-sm-12 col-md-6">
                          <div className="form__form-group ">
                            <span className="form__form-group-label">{item.label}</span>
                            <div className="form__form-group-field">
                              <input
                                name={item.name}
                                type="text"
                                placeholder={item.placeholder}
                                {...register(`${item.name}`)}
                              />
                            </div>
                          </div>
                        </div>

                      )
                    }) : null

                }
                <div className="col-sm-12 text-center pt-3">
                  <div>
                    <Button
                      outline
                      color="primary"
                      type="buttom"
                      onClick={cancelReset}
                    >
                      Cancel
                    </Button>

                    <Button color="primary" type="submit">
                      Submit
                    </Button>
                  </div>
                </div>
              </div>
            </form>
          </ModalBody>
        </Modal>
        :
        <div>
          < form className="form" autocomplete="off" onSubmit={handleSubmit(submit)}>
            <Loader loader={isLoading} />
            <div className='w-100'>
              <div className='row g-3'>
                {formFields && formFields.length && formFields.length > 0 &&
                  formFields.map((item, index) => (
                    <div className='col-md-6' key={index}>
                      {item.type === 'text' && (
                        <div className="form__form-group">
                          <div>
                            <Controller
                              name={item.name}
                              control={control}
                              render={({ field, fieldState }) => (
                                <DoInputField
                                  input={field}
                                  id={field.id}
                                  name={field.name}
                                  label={item.label}
                                  field={field}
                                  // normalize={item.name === "zip" || item.name === "contactPersonPhone" ? normalizenumber() : normalizenumber()}
                                  type="text"
                                  fieldState={fieldState}
                                  errors={errors}
                                  placeholder={item.placeholder}
                                />
                              )}
                            />
                          </div>
                        </div>
                      )}
                      {item.type === 'dropDown' && (
                        <div>
                          <Controller
                            name={item.name}
                            control={control}
                            render={({ field, fieldState }) => (
                              <DoSelectField
                                input={field}
                                name={field.name}
                                field={field}
                                label={item.label}
                                fieldState={fieldState}
                                errors={errors}
                                placeholder={item.placeholder}
                                options={
                                  item.name === 'selectPlanType'
                                    ? labels.map(label => ({ value: label.value, label: label.label }))
                                    : item.options
                                }
                                optionClassName='dropdown-option'
                              />
                            )}
                          />
                        </div>
                      )}
                      {item.type === 'password' && (
                        <div>
                          <Controller
                            name={item.name}
                            control={control}
                            render={({ field, fieldState }) => (
                              <DoPasswordField
                                input={field}
                                name={field.name}
                                field={field}
                                label={item.label}
                                fieldState={fieldState}
                                errors={errors}
                                placeholder={item.placeholder}
                              />
                            )}
                          />
                        </div>
                      )}
                    </div>
                  ))}
              </div>
            </div>
            <div className="form__form-group-field" style={{ marginTop: '0.5rem' }}>
              <input
                type="checkbox"
                style={{
                  marginRight: '8px',
                  height: '20px',
                  width: '20px',
                }}
                onChange={handleCheckboxChange}

              />
              <div>
                <label>
                  I have read and accepted the{' '}
                  <span onClick={openTermsConditions} className="custom-link">
                    Terms & Conditions{' '}
                  </span>
                  and{' '}
                  <span onClick={openPrivacyPolicy} className="custom-link">
                    Privacy Policy{' '}
                  </span>
                </label>
              </div>
            </div>


            <div className="w-100 mt-2">
              {config.displayRecaptcha && settingsData && settingsData.GooglesecretKey ?
                < ReCAPTCHA
                  sitekey={settingsData && settingsData.GooglesecretKey}
                  onChange={onChange}
                  type="image"
                  className='recap'
                />
                : null}
            </div>
            <div className='button_align'>
              <Button
                className="mt-2 mb-0"
                color="primary"
                disabled={isLoginDisabled || isRegisterButtonDisabled}
              >
                Register
              </Button>
            </div>
            {config.displayGoogleLogin ?
              <div className='line mt-4'>
                <div className='text'>
                  or
                </div>
              </div>
              : null
            }
            <div className='d-flex flex-column w-100 pb-3'>
              {config.displayGoogleLogin ?
                <div id="googleButton">
                  <div id="loginButton" className="strike w-100"></div>
                </div>
                : null
              }
            </div>
            <div className='py-2 position-relative top-negative '>
              <p>
                Back to <Link to="/log_in" className='text_decoration'>Login</Link>
              </p>
            </div>
            {isLoginSuccess ? <Navigate to="/dashboard" /> : null}
            {isGoogleLoginSuccess ? <Navigate to="/employees" /> : null}
          </form>
        </div >
      }
    </span >
  );
}


export default Register;

