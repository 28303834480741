import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import apiCalls from '../../../config/apiCalls';
import fetchMethodRequest from '../../../config/service';
import { Dropdown } from 'primereact/dropdown';
const RecommendedInventoryModal = (props) => {
    const [recommendedInventoryData, setRecommendedInventoryData] = useState([]);
    const [categoryType, setCategoryType] = useState('Medicine');
    const [filteredData, setFilteredData] = useState([]);
    const modal = document.querySelector('div[tabindex="-1"][style*="z-index: 1050;"]');
    if (modal) {
        modal.style.zIndex = '300';
    }
    useEffect(() => {
        getRecommendedInventoryData();
    }, []);

    useEffect(() => {
        // Set the categoryType dropdown value to 'Medicine' when the modal opens
        setCategoryType('Medicine');
    }, [props.openRecommendedInventoriesModal]);

    const getRecommendedInventoryData = async () => {
        const response = await fetchMethodRequest('GET', apiCalls.minInventoryByStates);
        setRecommendedInventoryData(response);
    }

    const categoryFilterValues = (e) => {
        setCategoryType(e.value);
    }

    // Define headers for the DataTable
    const headers = [
        { field: 'sn', header: 'S.No' }, // Add this line for serial number
        { field: 'productName', header: 'Product Name' },
        { field: 'description', header: 'Description' },
        { field: 'volume', header: 'Dosage' },
        { field: 'required', header: 'Required' },
        { field: 'inventoryType', header: 'Inventory Type' },
        { field: 'storageUnitType', header: 'Location Type' },
        { field: 'categoryName', header: 'Category Name' },
        { field: 'categoryType', header: 'Category Type' }
    ];


    // Function to get column value based on field
    const getColumnValue = (col, rowData, index) => {
        if (col.field === 'sn') {
            return rowData.sn; // Serial number starts from 1
        } else if (col.field === 'productName') {
            return (typeof rowData.productName === 'object') ? rowData.productName.productName : rowData.productName;
        } else if (col.field === 'categoryType') {
            return (typeof rowData.productName === 'object') ? rowData.productName.categoryType : rowData.categoryType;
        } else if (col.field === 'storageUnitType') {
            return (typeof rowData.productName === 'object') ? (rowData.productName.storageUnitType ? rowData.productName.storageUnitType.charAt(0).toUpperCase() + rowData.productName.storageUnitType.slice(1) : '') : (rowData.storageUnitType ? rowData.storageUnitType.charAt(0).toUpperCase() + rowData.storageUnitType.slice(1) : '');
        } else {
            return rowData[col.field] ? rowData[col.field] : '-';
        }
    };


    return (
        <div>
            <Modal isOpen={props.openRecommendedInventoriesModal} centered className={`modal-dialog-centered modal-dialog--primary modal-dialog--header logout_modal_width `} style={{ maxWidth: '90%', width: 'auto', maxHeight: '80vh' }}>
                <ModalHeader className="modal__header" style={{ justifyContent: "center" }}>
                    <button className="lnr lnr-cross modal__close-btn" type="button" onClick={props.closeRecommendedInventoryModal} style={{ color: 'white' }} />
                    <p className="bold-text  modal__title">{props.selectedOption} Details</p>
                </ModalHeader>
                <ModalBody className='p-2'>
                    <form className="form">
                        <div style={{ width: "100%" }}>
                            <div>
                                {props.getOptions.map((option, index) => {
                                    if (option.state && props.selectedOption === option.state.minInventoryByStateName) {
                                        return (
                                            <div key={index}>
                                                <div className='mx-1 mt-3'><span><b>Name:</b></span> {option.state.minInventoryByStateName}</div>
                                                <div className='mx-1 mt-3'><span><b>State:</b></span> {option.state.stateName} &nbsp;&nbsp;
                                                    {/* <div> */}
                                                    <label className='bold-text' style={{ marginLeft: '1rem' }}
                                                    >Category Type</label>                                                    <Dropdown
                                                        className='mr-3 categoryType_dropdown'
                                                        value={categoryType}
                                                        options={[
                                                            { label: "Medicine", value: "Medicine" },
                                                            { label: "Non-Medicine", value: "Non-Medicine" }
                                                        ]}
                                                        onChange={(e) => {
                                                            categoryFilterValues(e);
                                                        }}
                                                    />

                                                </div>
                                            </div>
                                        );
                                    }
                                    return null;
                                })}

                            </div>
                            <div className='row mx-1 mt-3' style={{ width: "100%" }}>
                                <div className="col-12 modalTbody pl-0 pr-0" style={{ width: "100%" }}>
                                    {props.getOptions.map((option, index) => {
                                        if (option.state && props.selectedOption === option.state.minInventoryByStateName) {
                                            // Filter minInventoryByStates based on categoryType
                                            const filteredStates = option.state.minInventoryByStates
                                                .filter(item => item.categoryType === categoryType)
                                                .map((item, i) => ({ ...item, sn: i + 1 }));
                                            return (
                                                <DataTable key={index} scrollable={true} scrollHeight="500px" tableStyle={{ minWidth: '50rem' }} showGridlines value={filteredStates}>
                                                    {headers.map((col, colIndex) => (
                                                        <Column key={colIndex} field={col.field} header={col.header} body={(rowData) => getColumnValue(col, rowData, colIndex)} />
                                                    ))}
                                                </DataTable>
                                            );
                                        }
                                        return null;
                                    })}
                                </div>
                            </div>
                        </div>
                    </form>
                </ModalBody>
            </Modal>
        </div>
    );
}

export default RecommendedInventoryModal;
