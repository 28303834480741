
import React from "react";
import { useState, useEffect, useRef } from "react";
import { Modal, ModalHeader, ModalBody, Card, CardBody, ButtonToolbar, Table, ButtonGroup, Row, Col, Badge } from 'reactstrap';
import { Button } from 'primereact/button';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, Controller } from 'react-hook-form';
import { Form } from "react-router-dom";
import DoSelectField from "../../../Form/Fields/DoSelectField";
import DoInputField from "../../../Form/Fields/DoInputField";

const AddCartDistributors = (props) => {

    let [schema, setSchema] = useState({});
    const [distributorsFields, setDistributorsFields] = useState(props.formFields);



    let {
        handleSubmit,
        register,
        reset,
        setValue,
        getValues,
        formState: { errors },
        control,
    } = useForm({
        resolver: yupResolver(schema),
    });


    useEffect(() => {
        if(props.type === "categories"){
            getCategorysSchema();
        }else{
            getDistributorsSchema();
        }

    }, []);


    const getCategorysSchema = () =>{
        setSchema(yup.object().shape({
            categoryName: yup.string().required('categoryName  is required'),
            categoryType: yup.string().required('categoryType  is required'),
            useType: yup.string().required('useType  is required'),
        }));
    }

    const getDistributorsSchema = () =>{
        setSchema(yup.object().shape({
            firstName: yup.string().required('firstName  is required'),
            lastName: yup.string().required('lastName  is required'),
            phoneNumber: yup.string().required('phoneNumber  is required'),
            distributorEmail: yup.string().required('distributorEmail  is required'),
            status: yup.string().required('status  is required')
        }));
    }

    // submit
    const submit = async (values) => {
        if (values) {
            props.sendDataToServer(values)
        }
    }

    // DropDown Field
    let getDropdown = (i, item) => {
        return (
            <div className={`${props.type == "categories" ? "col-md-6 p-2" : "col-md-4 p-2"}`}>
                {/* <div className="col-md-4 p-2"> */}
                <Controller
                    name={item.name}
                    control={control}
                    render={({ field, fieldState }) => (
                        <DoSelectField
                            input={field}
                            markReq={item.required}
                            id={field.id}
                            name={field.name}
                            field={field}
                            label={item.label}
                            fieldState={fieldState}
                            errors={errors}
                            options={item.options}
                            optionLabel={item.optionLabel ? item.optionLabel : 'label'}
                            placeholder={item.placeholder}
                        />)}
                />
            </div>
        )
    }

    // Text Field
    let getDefault = (i, item) => {
        return (
            <div className={`${props.type == "categories" ? "col-md-6 p-2" : "col-md-4 p-2"}`}>
                <Controller
                    name={item.name ? item.name : null}
                    control={control}
                    render={({ field, fieldState }) => (
                        <DoInputField
                            markReq={item.required}
                            input={field}
                            id={field.id}
                            name={field.name}
                            label={item.label}
                            field={field}
                            type={item.type ? item.type : "text"}
                            fieldState={fieldState}
                            errors={errors}
                            defVal={item.value}
                            placeholder={item.placeholder ? item.placeholder : null}
                        />)}
                />
            </div>
        )
    }

    return (
        <>
            <div className="wislistdistributorCard">
                <div className="card-body">

                    <form onSubmit={handleSubmit(submit)} autoComplete={'off'}>
                        <div className="row">
                            {
                                (distributorsFields && distributorsFields.length > 0) &&
                                distributorsFields.map((item, index) => {
                                    if (item.type === "dropDown") {
                                        return getDropdown(index, item)

                                    } else {
                                        return getDefault(index, item)
                                    }
                                })
                            }
                            
                                <div className={`${props.type === "categories" ? "col-6 " : "col-4" } p-2`} style={{ marginTop: "1.3rem" }}>
                                    <div className='d-flex'>
                                        <div style={{ flex: 1, marginRight: '5px', }}>
                                            <Button color='primary' className="categoryButtons" type="button" outline onClick={() => props.closeFormModal()}>
                                                {'Cancel'}
                                            </Button>
                                        </div>
                                        <div style={{ flex: 1 }}>
                                            <Button color='primary' className="categoryButtons" outlined type="submit">
                                                {"Save"}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                        </div>


                    </form>
                </div>
            </div>



        </>
    )
}
export default AddCartDistributors