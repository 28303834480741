import React, { useEffect, useState } from "react";
import { Dropdown } from 'primereact/dropdown';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle, faAsterisk, faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { useLocation } from 'react-router-dom';
import RecommendedInventoryIndicationModal from "../../Cruds/CommonModals/RecommendedInventoryIndicationModal";
import RecommendedInventoryModal from "../../Cruds/CommonModals/RecommendedInventoryModal";
const DoSelectField = (props) => {
  const [selectedOptionName, setSelectedOptionName] = useState();

  const id = props.id ? props.id : props.name;
  const label = props.label ? props.label : props.name;
  const optionLabel = props.optionLabel ? props.optionLabel : 'label';
  const name = props.name;
  const options = props.options;
  const placeholder = props.placeholder;
  const field = props.field;
  const location = useLocation();
  const isRegisterRoute = location.pathname.includes('register');
  const [showModal, setShowModal] = useState(false); // State to control modal visibility
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (props.onChange) {
      props.onChange(field.value)
    }
  }, [field.value]);

  const markRequired = () => {
    return (
      <FontAwesomeIcon
        color='red'
        icon={faAsterisk}
        // className="mark_required"
        style={{ width: '7px', marginBottom: '5px', marginLeft: '2px' }}

      />
    );
  };

  // Function to handle opening the modal
  const openModal = () => {
    const selectedOption = field.value;
    setShowModal(true);

  };

  const openIndicationModal = () => {
    setIsModalOpen(true);

  }

  const capitalizeFirstLetter = (string) => {
    if (!string) return '';

    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };
  // Function to handle closing the modal
  const closeModal = () => {
    setShowModal(false);
    setIsModalOpen(false)
  };

  const renderOptionWithIcon = (option) => {
    return (
      <div className="option-with-icon d-flex flex-wrap justify-content-between align-items-center" style={{ gap: '10rem' }} >
        <span>{option[optionLabel]}</span>
        {name === 'minInventoryByStateName' && (
          <FontAwesomeIcon
            className="template_icon"
            icon={faExclamationCircle}
            style={{ color: "#3475aa", cursor: "pointer" }}
            onClick={openModal} // Call openModal when the icon is clicked
          />
        )}
      </div>

    );
  };

  return (
    <div className="flex flex-column Do_Select ">
      <div className="flex align-center">
        {props.type === "UploadIcon" ? "" : <label htmlFor={id} className="text-capitalize">{label}</label>}
        {name === 'minInventoryByStateName' && (
          <span style={{ cursor: 'pointer' }}>
            <FontAwesomeIcon
              className="template_icon icon_align"
              icon={faQuestionCircle}
              onClick={openIndicationModal}
            />
          </span>
        )}
        {/* Not Using for Now */}
        {props.markReq && props.markReq === true && markRequired()}
      </div>
      <Dropdown
        value={field.value}
        optionLabel={optionLabel}
        placeholder={placeholder}
        name={name}
        options={options}
        focusInputRef={field.ref}
        onChange={(e) => field.onChange(e.value)}
        itemTemplate={renderOptionWithIcon} // Use the custom item template
        style={isRegisterRoute ? { maxHeight: '3rem', padding: '0px' } : {}}
        disabled={props.isDisable}
      />
      <small className="text-danger ">
        {props.fieldState.invalid ?
          capitalizeFirstLetter(props.errors[props.name]?.message) : ''}
      </small>
      {name === 'minInventoryByStateName' ?
        <RecommendedInventoryModal openRecommendedInventoriesModal={showModal} closeRecommendedInventoryModal={closeModal} tableRowData={props.options}
          getOptions={options} selectedOption={field.value}
        />
        : null}
      {isModalOpen && (
        <RecommendedInventoryIndicationModal
          isOpen={isModalOpen}
          closeModal={closeModal}
        />
      )}

    </div>
  );
};

export default DoSelectField;
