import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ExpirationItems from './components/ExpirationItems';

const expirationItems = (props, { t }) => (
  <Container>
    <ExpirationItems {...props} />
  </Container>
);

expirationItems.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(expirationItems);
