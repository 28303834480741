import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import OrdersCount from './components/OrdersCount';
const orderItems = (props, { t }) => (
    <Container>
        <OrdersCount {...props} />
    </Container>
);

orderItems.propTypes = {
    t: PropTypes.func.isRequired,
};

export default withTranslation('common')(orderItems);
