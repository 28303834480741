import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'reactstrap';
import { NavLink } from 'react-router-dom';
//icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
//config
import config from '../../../config/config';
library.add(fas);
const SidebarLink = ({
  title, icon, newLink, route, onClick, iconType, isSubmenu
}) => (
  <NavLink
    to={route}
    onClick={onClick}
    activeClassName="sidebar__link-active"
    activeStyle={{ backgroundColor: config.darkTemplateColor, color: config.whiteColor }}
  // activeStyle={{ backgroundColor: '#4c89bb' }}
  >
    {/* <row className="sidebar__link"> */}
    <li className="sidebar__link">
      {icon ?
        title == 'Battery Checks' || title == 'Storage Unit Checks' || title == 'Recall Items' ||
          title == 'Payments' ?
          <FontAwesomeIcon icon={icon} className='sidebar__link-fontawsome-icon' />
          :
          <span className={`sidebar__link-icon lnr lnr-${icon}`} />
        : ''}

      <p className="sidebar__link-title" activeClassName='sidebar__link-active'
      // style={isSubmenu && isSubmenu==true ?{paddingLeft:"2px"}:{paddingLeft:"0px"}}
      >

        {title}
        {newLink ? <Badge className="sidebar__link-badge"><span>New</span></Badge> : ''}
      </p>
    </li>
  </NavLink>
);

SidebarLink.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
  newLink: PropTypes.bool,
  route: PropTypes.string,
  onClick: PropTypes.func,
  iconType: PropTypes.string
};

SidebarLink.defaultProps = {
  icon: '',
  newLink: false,
  route: '/',
  onClick: () => { },
};

export default SidebarLink;