import React, { useEffect, useState } from 'react';
import { Nav, NavItem, NavLink, Button } from 'reactstrap';
import fetchMethodRequest from '../../../config/service';
import classnames from 'classnames';
import showToasterMessage from '../../UI/ToasterMessage/toasterMessage';


const OrderTabTypes = (props) => {
    const [activeTab, setActiveTab] = useState(0);
    const [currentShift, setCurrentShift] = useState(null);
    const [filterCriteria, setFilterCriteria] = useState({ limit: 20, page: 1, criteria: [], sortfield: 'created', direction: 'desc' });

    const orderTypes = [
        { name: 'Orders List', value: 'Orders List' },
        { name: 'Placed Orders', value: 'Placed Orders' },
        { name: 'Fulfilled Orders', value: 'Fulfilled Orders' }
    ];
    useEffect(() => {
        props.getSelectedOrderTypeInfo('Orders List');
    }, []);

    const handleOrderTypes = (tab, OrderTabType,) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
            setCurrentShift(OrderTabType);
        }
        props.getSelectedOrderTypeInfo(OrderTabType);
    };

    // const sendEmail = () => {
    //     let userBody = props.selectedRows;
    //     if (userBody) {
    //         let requestBody = { orders: userBody };

    //         return fetchMethodRequest('POST', 'orders/sendEmail', requestBody)
    //             .then((response) => {
    //                 if (response && response.respCode && response.respCode === 200) {
    //                     showToasterMessage(response.respMessage, 'success');
    //                 } else if (response && response.errorMessage) {
    //                     showToasterMessage(response.errorMessage, 'error');
    //                 }
    //                 props.getDataFromServer(filterCriteria, 'Orders List');
    //             })
    //             .catch((err) => {
    //                 return err;
    //             });
    //     }
    // };



    return (
        <div>
            <div className="tabs tabs--justify tabs--bordered-top">
                <div className="tabs__wrap">
                    {/* {activeTab === 0 ? (
                        <div className='align-tabtypes'>
                            <Button className="ml-auto my-auto" color="primary" onClick={props.sendEmail()} outline>
                                Submit
                            </Button>
                        </div>
                    ) : null} */}
                    <Nav tabs>
                        {orderTypes && orderTypes.length > 0 ? (
                            orderTypes.map((orderTabType, index) => (
                                <NavItem key={index} className='tabType'>
                                    <NavLink
                                        className={classnames({ active: activeTab === index })}
                                        onClick={() => {
                                            handleOrderTypes(index, orderTabType.name);
                                        }}
                                    >
                                        <span style={{ fontSize: 15 }}>{orderTabType.value}</span>
                                    </NavLink>
                                </NavItem>
                            ))
                        ) : null}
                    </Nav>
                </div>
            </div>
        </div>
    );
};

export default OrderTabTypes;
