import React from 'react'
import { useState, useEffect, useRef } from "react";
import { Modal, ModalHeader, ModalBody, Card, CardBody, ButtonToolbar, Table, ButtonGroup, Row, Col, Badge } from 'reactstrap';
import { Button } from 'primereact/button';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, Controller } from 'react-hook-form';
import { Form } from "react-router-dom";
import DoSelectField from "../..//Form/Fields/DoSelectField";
import showToasterMessage from '../../UI/ToasterMessage/toasterMessage';
import fetchMethodRequest from '../../../config/service';




function UploadIconModal(props) {

    const lotId = props.tableRowData.lotId;
    const [tableRowData, setTableRowData] = useState(props.tableRowData);
    let [schema, setSchema] = useState({});
    const options = [
        { label: "Consumed", value: "consumed" },
        { label: "Recalled", value: "recalled" },
        { label: "Expired", value: "expired" }
    ];

    let {
        handleSubmit,
        register,
        reset,
        setValue,
        getValues,
        formState: { errors },
        control,
    } = useForm({
        resolver: yupResolver(schema),
    });


    useEffect(() => {

        setSchema(yup.object().shape({
            useType: yup.string().required('useType  is required'),
        }));

        const modal = document.querySelector('div[tabindex="-1"][style*="z-index: 1050;"]');
        if (modal) {
            modal.style.zIndex = '300';
        }
    }, []);



    const saveDataToServer = (formValues) => {
        let apiUrl = `productQuantities/useItem?lotId=${lotId}`
        let method = "POST"
        let userBody = Object.assign({}, formValues);

        if (formValues) {
            userBody.itemId = tableRowData._id;
            userBody.storageUnitName = tableRowData.storageUnitName;
            userBody.companyId = tableRowData.companyId;
            userBody.productName = tableRowData.productName;
            userBody.lotId = tableRowData.lotId;
            userBody.storageUnitId = props.storageUnitId
        }

        return fetchMethodRequest(method, apiUrl, userBody)
            .then(async (response) => {
                if (response && response.respCode) {
                    props.closeFormModal();
                    await props.getDataFromServer(undefined, props.categoryType);
                    showToasterMessage(response.respMessage, 'success');
                } else if (response && response.errorMessage) {
                    showToasterMessage(response.errorMessage, 'error');
                }

            }).catch((err) => {
                return err;
            });
    }

    const submit = (values) => {
        if (values) {
            saveDataToServer(values);
        }
    }

    return (
        <div style={{ alignItems: "center" }}>
            <Modal isOpen={props.openStorageUnitModal}
                className='modal-dialog-centered modal-dialog--primary m-auto logout_modal_width'
            >
                <ModalBody className='deleteModalBody'>

                    <form onSubmit={handleSubmit(submit)} autoComplete={'off'}>
                        <div className={"col-md-10 p-2"}>
                            <Controller
                                name={"useType"}
                                control={control}
                                render={({ field, fieldState }) => (
                                    <DoSelectField
                                        input={field}
                                        markReq={false}
                                        id={field.id}
                                        name={field.name}
                                        field={field}
                                        type={"UploadIcon"}
                                        // label={"Use Type"}
                                        fieldState={fieldState}
                                        errors={errors}
                                        options={options}
                                        optionLabel={'label'}
                                        placeholder={"Use Type"}
                                    />)}
                            />
                        </div>

                        <div className={`col-10  p-2`} style={{ marginTop: "1.3rem" }}>
                            <div className='d-flex'>
                                <div style={{ flex: 1, marginRight: '5px', }}>
                                    <Button color='primary' className="categoryButtons" type="button" outline onClick={() => props.closeFormModal()}>
                                        {'Cancel'}
                                    </Button>
                                </div>
                                <div style={{ flex: 1 }}>
                                    <Button color='primary' className="categoryButtons" outlined type="submit">
                                        {"Submit"}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </form>

                </ModalBody>


            </Modal>
        </div>
    );
}

export default UploadIconModal
