import React from "react";
import { InputText } from 'primereact/inputtext';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAsterisk } from "@fortawesome/free-solid-svg-icons";

const DoInputField = (props) => {
  const id = props.id ? props.id : props.name;
  const label = props.label ? props.label : props.name;
  const name = props.name;
  const type = props.type ? props.type : 'text';
  let field = props.field;
  const placeholder = props.placeholder;
  const normalize = props.normalize;
  let markRequired = () => {
    return (
      <FontAwesomeIcon
        color='red'
        icon={faAsterisk}
        style={{ width: '7px', marginBottom: "5px", marginLeft: "2px" }}
      />
    );
  };

  const normalizenumber = (value) => {
    if (value == null) {
      return value;
    }
    const stringValue = value.toString();
    const onlyNums = stringValue.replace(/\D/g, "");
    return onlyNums;
  };


  const normalizePhoneNumber = (value) => {
    if (!value) return value;
    const onlyNums = value.replace(/\D/g, "");
    let formattedPhoneNumber = "";

    // Format the first three digits
    if (onlyNums.length > 0) {
      formattedPhoneNumber = "(" + onlyNums.substring(0, 3);
    }

    // Format the next three digits if available
    if (onlyNums.length > 3) {
      formattedPhoneNumber += ") " + onlyNums.substring(3, 6);
    }

    // Format the remaining digits
    if (onlyNums.length > 6) {
      formattedPhoneNumber += "-" + onlyNums.substring(6, 10);
    }

    return formattedPhoneNumber;
  };



  const capitalizeFirstLetter = (string) => {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };


  const getInputStyles = () => {
    return props.isDisable ? { backgroundColor: 'lightgray' } : {};
  };

  return (
    < div className="flex flex-column doInput" >
      <div className="flex align-center"> {/* Added a wrapper div with flex class */}
        <label htmlFor={id} className="text-capitalize">{label}</label>
        {/* Not Using for Now */}
        {props.markReq && props.markReq === true && markRequired()}
      </div>

      <div className="d-flex align-items-center">
        {props.isShowIcon && (
          <span className="p-inputgroup-addon" style={{ height: '2.45rem', marginBottom: '7px' }}>
            <i className="pi pi-user" style={{ padding: '2px' }}></i>
          </span>
        )}

        <InputText
          type={type}
          onKeyDown={(e) => {
            if (type === 'number' && (e.key === 'e' || e.key === 'E')) {
              e.preventDefault();
            }
          }}
          value={name === "InvoiceNumber" || name === 'noofDrawers' || name === 'required' || name === 'addedInventory' ? normalizenumber(field.value) : name === "contactPersonPhone" || name === 'phone' || name === 'phoneNumber' ? normalizePhoneNumber(field.value) : field.value}
          id={id}
          name={name}
          placeholder={placeholder}
          disabled={props.isDisable}
          style={{ ...props.style, ...getInputStyles() }}
          aria-describedby="username-help"
          onChange={(e) => { if (props.onChange) { props.onChange(e) }; field.onChange(e.target.value) }}
          className="flex-grow-1"
        />
      </div>

      <small className="text-danger ">
        {props.fieldState.invalid ?
          capitalizeFirstLetter(props.errors[props.name]?.message) : ''}
      </small>

    </div >
  )

}

export default DoInputField;
