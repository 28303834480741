import React, { useEffect, useState } from "react";
import { AutoComplete } from 'primereact/autocomplete';
import fetch from '../../../config/service';
import config from "../../../config/config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAsterisk } from "@fortawesome/free-solid-svg-icons";

const DoAutoCompleteField = (props) => {
    const [filteredSuggestions, setFilteredSuggestions] = useState([]);
    const [isValidate, setIsValidate] = useState(false);
    const [noData, setNoData] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [products, setProducts] = useState(undefined);
    const [companyId, setCompanyId] = useState('');
    const id = props.id ? props.id : props.name;
    const label = props.label ? props.label : props.name;
    const name = props.name;
    const type = props.type ? props.type : 'text';
    let field = props.field;
    const placeholder = props.placeholder;
    const item = props.item ? props.item : {}

    useEffect(() => {
        // getAllSuggestions();
        let loggedUser = JSON.parse(localStorage.getItem('loginCredentials'));
        setCompanyId(loggedUser._id);
    }, [])

    // const isProduct = (value , name) =>{
    //     if(value){
    //         if(name == "description"){
    //             setProducts(value.productName);
    //         }
    //     }
    // }

    const onSelectRecord = (e) => {
        setNoData(false);
        setIsValidate(false);
        setErrorMessage('');

        if (props.onChange) {
            // const { name, onChange } = props.input;
            props.onChange(e.value);
            // props.handleAutoCompleteData(e.value, name)
            // isProduct(e.value, name)
            // if (e && e.value) {
            //    removeDuplicates(e.value, name);
            // }
        }
        if (props.screen === 'Min Inventory By Products' && props.item && props.item.dependent) {
            if (e.value[props.item.dependent.key] == props.item.dependent.value) {
                props.handleFnEnableControlsBasedOnValue1(props.item.dependent.field, true)
            } else {
                props.handleFnEnableControlsBasedOnValue1(props.item.dependent.field, false)
            }
        }
    }


    const capitalizeFirstLetter = (string) => {
        if (!string) return '';

        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    };

    // to remove duplicates
    const removeDuplicates = () => {
        if (this.state.filteredSuggestions) {
            setSuggestions(this.state.filteredSuggestions);
        }
    }

    const getAllSuggestions = (event, type) => {
        let url;
        // this.setState({
        //     isValidate: false,
        //     noData: false,
        //     errorMessage: ''
        // });
        setIsValidate(false);
        setNoData(false);
        setErrorMessage('');
        let filterCriteria = {
            limit: 30,
            page: 1,
            sortfield: "created",
            direction: "desc",
            criteria: []
        };

        if (props.item.hasDependencyField && props.getValues(props.item.hasDependencyField) && props.getValues(props.item.hasDependencyField)["_id"]) {
            filterCriteria['criteria'].push({
                "key": props.item.fieldForKey,
                "value": props.getValues(props.item.hasDependencyField)["_id"],
                // "value": props.getValues(props.item.hasDependencyField)[props.item.fieldForSearch] ? props.getValues(props.item.hasDependencyField)[props.item.fieldForSearch] : props.getValues(props.item.hasDependencyField)["_id"],

                "type": "eq"
            })
        }
        if (item.customCriteria && item.customCriteria.length > 0) {
            filterCriteria['criteria'] = [...filterCriteria['criteria'], ...item.customCriteria]
        }


        if (event && event.query) {
            filterCriteria['criteria'].push({
                "key": props.searchField,
                "value": event.query,
                "type": "regexOr"
            });
        };
        let body;
        let apiUrl = props.searchApi;
        let method = "GET"
        url = `${apiUrl}?searchFrom=autoComplete&filter=${JSON.stringify(filterCriteria)}`;
        // return fetch('GET', url)

        if (props.screen == "Inventory Check Lists" || props.screen == "Product Quantities" || props.screen == "dashBoard") {

            method = props.name === "description" ? "POST" : "GET"
            body = {
                productName: props.name === "description" ? props.isProductName : null
            };
        } else {
            body = {
                productName: props.productName
            };
        }
        return fetch(
            method,
            url,
            body
        )
            .then((response) => {
                if (response) {
                    if (response && ((response.distributors && response.distributors.length == 0) || (response.facilities && response.facilities.length === 0) || (response.productQuantities && response.productQuantities.length === 0))) {
                        setNoData(true);
                        setFilteredSuggestions([]);
                        setNoData(true);
                    }
                    let dropdownData = [];
                    if (response[apiUrl] && response[apiUrl].length && response[apiUrl].length > 0) {
                        dropdownData = response[apiUrl];
                    }
                    else if (props && props.screen === 'Recall Items' && response && response.productQuantities.length && response.productQuantities.length > 0 && props.name === 'lotId') {
                        dropdownData = response["productQuantities"];
                    }
                    else if (response && response.products.length && response.products.length > 0 && props.name == "description") {
                        dropdownData = response["products"];
                    }
                    if (dropdownData && dropdownData.length == 0) {
                        setFilteredSuggestions([]);
                        setNoData(true);
                    } else {
                        setSuggestions(dropdownData);
                    }
                }
            }).catch((err) => {
                return err;
            });
    }

    const setSuggestions = async (dropdownData) => {
        if (props.input && props.input.value) {
            if (props.input.value.length > 0) {
                let values = props.input.value;
                if (Array.isArray(values)) {
                    values.forEach(element => {
                        let field = '_id';
                        dropdownData = dropdownData.filter((item) => item[field] !== element[field]);
                    });
                }
            }
        }
        setFilteredSuggestions(dropdownData);
    }

    let markRequired = () => {
        return (
            <FontAwesomeIcon
                color='red'
                icon={faAsterisk}
                style={{ width: '7px', marginBottom: "5px", marginLeft: "2px" }}
            />
        );
    };

    const onSelect = (e) => {
        field.onChange(e);
        if (props.onChange) {
            props.onChange(e.value)
        }
    }

    return (
        // <div className={`${props.name=="distributorEmail"&& props.searchField=="distributorEmail" ?  "doInput flex flex-column col-md-10" : "doInput flex flex-column"}`}>
        <div className={`${props.displayCol ? props.displayCol : ""} ${props.multiple ? "" : "doInput"} flex flex-column`}>

            <div className="flex align-center"> {/* Added a wrapper div with flex class */}
                <label htmlFor={id} className="text-capitalize">{props.customLabel ? props.customLabel(props) : label}</label>
                {/* Not Using for Now */}
                {props.markReq && props.markReq === true && markRequired()}
            </div>
            <AutoComplete
                inputId={field.name}
                value={field.value}
                onChange={onSelect}
                // onSelect={onSelect}
                // inputRef={field.ref}
                suggestions={filteredSuggestions}
                completeMethod={getAllSuggestions}
                placeholder={placeholder}
                field={props.searchField}
                onSelect={onSelectRecord}
                dropdown={true}
                multiple={props.multiple}
                selectionLimit={props.selectionLimit ? props.selectionLimit : config.selectionLimit}
                disabled={props.isDisable}
            />

            <small className="text-danger ">
                {props.fieldState.invalid ?
                    capitalizeFirstLetter(props.errors[props.name]?.message) : ''}
            </small>
            {/* {noData && <span className="form__form-group-error" style={{ marginTop: '5px' }}>
                {props.searchApi == 'distributors' ? 'No Data Found' : 'No Data Found'}
            </span>} */}
            {noData && <span className="form__form-group-error" style={{ marginTop: '5px' }}>
                {props.searchApi == 'distributors' ? 'No Data Found' : props.searchApi == 'facilities' ? 'No Data Found' : props.searchApi == 'productQuantities' ? 'No Data Found' : 'No Data Found'}

            </span>}
        </div>
    )

}

export default DoAutoCompleteField;