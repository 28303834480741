import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import CompanyPayments from './components/CompanyPayments';
const getPayments = (props, { t }) => (
    <Container>
        <CompanyPayments {...props} />
    </Container>
);

getPayments.propTypes = {
    t: PropTypes.func.isRequired,
};

export default withTranslation('common')(getPayments);
