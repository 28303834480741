const commonSampleFilePath = "http://localhost:3000/images/sample";

const configSampleFiles = {
  employees:
    "http://api.crashcartv4.dosystemsinc.com/images/sample_employees_bulk_upload_file.csv",
  activities:
    "http://api.crashcartv4.dosystemsinc.com/images/sample_activities_bulk_upload_file.csv",
  emailtemplates:
    "http://api.crashcartv4.dosystemsinc.com/images/sample_emailtemplates_bulk_upload_file.csv",
  roles:
    "http://api.crashcartv4.dosystemsinc.com/images/sample_roles_bulk_upload_file.csv",
  uploadhistories:
    "http://api.crashcartv4.dosystemsinc.com/images/sample_uploadhistories_bulk_upload_file.csv",
  emailstatuses:
    "http://api.crashcartv4.dosystemsinc.com/images/sample_emailstatuses_bulk_upload_file.csv",
  facilities:
    "http://api.crashcartv4.dosystemsinc.com/images/sample_facilities_bulk_upload_file.csv",
  storageunits:
    "http://api.crashcartv4.dosystemsinc.com/images/sample_storageunits_bulk_upload_file.csv",
  cartlocations:
    "http://api.crashcartv4.dosystemsinc.com/images/sample_cartlocations_bulk_upload_file.csv",
  supplycabinets:
    "http://api.crashcartv4.dosystemsinc.com/images/sample_supplycabinets_bulk_upload_file.csv",
  orsuites:
    "http://api.crashcartv4.dosystemsinc.com/images/sample_orsuites_bulk_upload_file.csv",
  mininventorybystates:
    "http://api.crashcartv4.dosystemsinc.com/images/sample_mininventorybystates_bulk_upload_file.csv",
  categories:
    "http://api.crashcartv4.dosystemsinc.com/images/sample_categories_bulk_upload_file.csv",
  products:
    "http://api.crashcartv4.dosystemsinc.com/images/sample_products_bulk_upload_file.csv",
  storageunititems:
    "http://api.crashcartv4.dosystemsinc.com/images/sample_storageunititems_bulk_upload_file.csv",
  productquantities:
    "http://api.crashcartv4.dosystemsinc.com/images/sample_productquantities_bulk_upload_file.csv",
  inventorychecklists:
    "http://api.crashcartv4.dosystemsinc.com/images/sample_inventorychecklists_bulk_upload_file.csv",
  expirationitems:
    "http://api.crashcartv4.dosystemsinc.com/images/sample_expirationitems_bulk_upload_file.csv",
  expireditems:
    "http://api.crashcartv4.dosystemsinc.com/images/sample_expireditems_bulk_upload_file.csv",
  missingitems:
    "http://api.crashcartv4.dosystemsinc.com/images/sample_missingitems_bulk_upload_file.csv",
  recallitems:
    "http://api.crashcartv4.dosystemsinc.com/images/sample_recallitems_bulk_upload_file.csv",
  itemsrecalleds:
    "http://api.crashcartv4.dosystemsinc.com/images/sample_itemsrecalleds_bulk_upload_file.csv",
  recalls:
    "http://api.crashcartv4.dosystemsinc.com/images/sample_recalls_bulk_upload_file.csv",
  storageunitchecks:
    "http://api.crashcartv4.dosystemsinc.com/images/sample_storageunitchecks_bulk_upload_file.csv",
  batterychecks:
    "http://api.crashcartv4.dosystemsinc.com/images/sample_batterychecks_bulk_upload_file.csv",
  facilitystaffs:
    "http://api.crashcartv4.dosystemsinc.com/images/sample_facilitystaffs_bulk_upload_file.csv",
  orders:
    "http://api.crashcartv4.dosystemsinc.com/images/sample_orders_bulk_upload_file.csv",
  placedorders:
    "http://api.crashcartv4.dosystemsinc.com/images/sample_placedorders_bulk_upload_file.csv",
  fulfilledorders:
    "http://api.crashcartv4.dosystemsinc.com/images/sample_fulfilledorders_bulk_upload_file.csv",
  requiredinventories:
    "http://api.crashcartv4.dosystemsinc.com/images/sample_requiredinventories_bulk_upload_file.csv",
  mininventorybyproducts:
    "http://api.crashcartv4.dosystemsinc.com/images/sample_mininventorybyproducts_bulk_upload_file.csv",
  distributors:
    "http://api.crashcartv4.dosystemsinc.com/images/sample_distributors_bulk_upload_file.csv",
  helps:
    "http://api.crashcartv4.dosystemsinc.com/images/sample_helps_bulk_upload_file.csv",
  recommendedinventories:
    "http://api.crashcartv4.dosystemsinc.com/images/sample_recommendedinventories_bulk_upload_file.csv",
  companyusers:
    "http://api.crashcartv4.dosystemsinc.com/images/sample_companyusers_bulk_upload_file.csv",
  alerts:
    "http://api.crashcartv4.dosystemsinc.com/images/sample_alerts_bulk_upload_file.csv",
  notifications:
    "http://api.crashcartv4.dosystemsinc.com/images/sample_notifications_bulk_upload_file.csv",
};
export default configSampleFiles;
