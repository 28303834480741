const imagePath = '../assets/';
const configImages = {
  defaultImg: require(`../assets/img/profile/defaultImg.png`),
  defaultUserImage: require(`../assets/img/profile/default_user_image.png`),
  employeesSampleXL: require(`../assets/files/scheduleSampleUpload.xlsx`),
  loginImage: require(`../assets/img/logo/inmedtoryLogo.png`),
  CardsIcon: require(`../assets/img/logo/cards.png`),

  // loginImage: require(`../assets/img/logo/1689673177344_Screenshot from 2023-07-11 19-41-22.png`),
  // loginSideImage: require(`../assets/img/logo/loginSideImageName`),
  // loginLogo: require(`../assets/img/logo/loginLogo.jpg`),
  loaderImage: require(`../assets/loader.gif`)

};
export default configImages;
