import React from 'react'
import { Modal, ModalHeader, ModalBody, Card, CardBody, ButtonToolbar, Table, ButtonGroup, Row, Col, Badge } from 'reactstrap';
import { Button } from 'primereact/button';


function OrderConfirmationModal(props) {
  return (
    
        <div>
            <Modal isOpen={props.Order} className='deleteModalHeight'>
                <ModalBody className='deleteModalBody'>
                    <div style={{ marginBottom: 10}}>Order already exists in Orders List, do you want to continue ?</div>
                    <Button color="primary m-2" outlined onClick={props.closeOrderConfirmation} className='deleteModalBtn marginRight btn custom-outline'>No</Button>
                    <Button  color="primary" outlined onClick={props.openOrderConfirmation} style={{ marginLeft: '0.5rem' }}
                        className='deleteModalBtn btn custom-outline'>Yes</Button>
                </ModalBody>
            </Modal>
        </div>
    
  );
}

export default OrderConfirmationModal
