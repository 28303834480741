import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import apiCalls from '../../../config/apiCalls';
import fetchMethodRequest from '../../../config/service';
import { Dropdown } from 'primereact/dropdown';
const InventoryModal = (props) => {
    const [recommendedInventoryData, setRecommendedInventoryData] = useState([]);
    // let [serialNumber, setSerialNumber] = useState(1); // Initialize serial number counter
    const [categoryType, setCategoryType] = useState('Medicine');
    const [filteredData, setFilteredData] = useState([]);

    useEffect(() => {

        const modal = document.querySelector('div[tabindex="-1"][style*="z-index: 1050;"]');
        if (modal) {
            modal.style.zIndex = '300';
        }

        getRecommendedInventoryData();
    }, []);

    useEffect(() => {
        // Set the categoryType dropdown value to 'Medicine' when the modal opens
        setCategoryType('Medicine');
    }, [props.openRecommendedInventoriesModal]);

    useEffect(() => {

        const modal = document.querySelector('div[tabindex="-1"][style*="z-index: 1050;"]');
        if (modal) {
            modal.style.zIndex = '300';
        }
        filterData(categoryType);
    }, [categoryType]);

    const filterData = (type) => {
        let filtered;
        if (type === 'Medicine') {
            filtered = props.tableRowData.minInventoryByStates.filter(item => item.categoryType === 'Medicine');
        } else if (type === 'Non-Medicine') {
            filtered = props.tableRowData.minInventoryByStates.filter(item => item.categoryType === 'Non-Medicine');
        }
        filtered = filtered.map((item, index) => ({ ...item, sn: index + 1 }));
        setFilteredData(filtered);
    }


    const getRecommendedInventoryData = async () => {
        const response = await fetchMethodRequest('GET', apiCalls.minInventoryByStates);
        setRecommendedInventoryData(response);
    }

    const categoryFilterValues = (e) => {
        setCategoryType(e.value);
    }


    // Define headers for the DataTable
    const headers = [
        { field: 'sn', header: 'S.No' },
        { field: 'productName', header: 'Product Name' },
        { field: 'description', header: 'Description' },
        { field: 'volume', header: 'Dosage' },
        { field: 'required', header: 'Required' },
        { field: 'inventoryType', header: 'Inventory Type' },
        { field: 'storageUnitType', header: 'Location Type' },
        { field: 'categoryName', header: 'Category Name' },
        { field: 'categoryType', header: 'Category Type' }
    ];

    const getColumnValue = (col, rowData) => {
        if (col.field === 'sn') {
            return rowData.sn;
        }
        else if (col.field === 'productName') {
            return (typeof rowData.productName === 'object') ? rowData.productName.productName : rowData.productName;
        } else if (col.field === 'categoryType') {
            return (typeof rowData.productName === 'object') ? rowData.productName.categoryType : rowData.categoryType;
        } else if (col.field === 'storageUnitType') {
            return (typeof rowData.productName === 'object') ? (rowData.productName.storageUnitType ? rowData.productName.storageUnitType.charAt(0).toUpperCase() + rowData.productName.storageUnitType.slice(1) : '') : (rowData.storageUnitType ? rowData.storageUnitType.charAt(0).toUpperCase() + rowData.storageUnitType.slice(1) : '');
        } else {
            return rowData[col.field] ? rowData[col.field] : '-';
        }
    };

    return (
        <div>
            <div>
                <Modal isOpen={props.openRecommendedInventoriesModal} centered className={`modal-dialog-centered modal-dialog--primary modal-dialog--header logout_modal_width `} style={{ maxWidth: '90%', width: 'auto', maxHeight: '80vh' }}>
                    <ModalHeader className="modal__header" style={{ justifyContent: "center" }}>
                        <button className="lnr lnr-cross modal__close-btn" type="button" onClick={props.closeRecommendedInventoryModal} style={{ color: 'white' }} />
                        <p className="bold-text  modal__title" style={{ fontWeight: 'bold' }}>Recommended Inventories Details</p>
                    </ModalHeader>
                    <ModalBody className='p-2'>
                        <form className="form">
                            <div>
                                <label className='bold-text' style={{ marginLeft: '1rem' }}
                                >Category Type</label>
                                <Dropdown
                                    className='categoryType_dropdown'
                                    value={categoryType}
                                    style={{ marginLeft: '1rem' }}
                                    options={[
                                        { label: "Medicine", value: "Medicine" },
                                        { label: "Non-Medicine", value: "Non-Medicine" }
                                    ]}
                                    onChange={(e) => {
                                        categoryFilterValues(e);
                                    }}
                                />

                            </div>
                            <div style={{ width: "100%" }}>
                                <div className='row mx-1 mt-3' style={{ width: "100%" }}>
                                    <div className="col-12 modalTbody pl-0 pr-0" style={{ width: "100%", overflowX: "auto" }}>
                                        <DataTable scrollable={true} scrollHeight="500px" tableStyle={{ minWidth: '50rem' }} showGridlines value={filteredData}>
                                            {headers.map((col, index) => (
                                                <Column key={index} field={col.field} header={col.header} body={(rowData) => getColumnValue(col, rowData)} />
                                            ))}
                                        </DataTable>

                                    </div>
                                </div>
                            </div>
                        </form>
                    </ModalBody>
                </Modal>
            </div>
        </div>
    );
}

export default InventoryModal;


