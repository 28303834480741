import React from 'react';
import { Modal, ModalBody, Button } from 'reactstrap';
import classNames from 'classnames';

const MailSentConfirmationModal = (props) => {
    const modalClass = classNames({
        'modal-dialog--colored': props.colored,
        'modal-dialog--header': props.header,
    });

    return (
        <div>
            <Modal isOpen={props.isEmailConfirmationModal}
                className={`modal-dialog-centered modal-companySuccess modal-dialog--primary  ${modalClass}`}>
                <ModalBody className='deleteModalBody'>
                    {/* <div style={{
                        background: config.templateColor, color: 'white', display: 'flex', justifyContent: 'center',
                        height: 45, alignItems: 'center', fontSize: 17, fontWeight: 'bold', borderRadius: 5
                    }}>
                        Success
                    </div> */}
                    {/* <div className="text-center">
                        <h4 className="pt-4 pb-3">{props.mailSentMessage}</h4>
                    </div> */}
                    <div className="text-center pt-4">
                        <h4 className="pb-5">Company created and verified successfully</h4>
                    </div>
                    <div className="confirm-modal">
                        <Button color='primary' onClick={props.closeModal}>
                            <span style={{ color: 'white' }}>Done</span>
                        </Button>
                    </div>
                </ModalBody>
            </Modal>
        </div>
    );
}

export default MailSentConfirmationModal;
