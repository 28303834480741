import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import TopbarSidebarButton from './TopbarSidebarButton';
import TopbarProfile from './TopbarProfile';
import SessionExpiryModal from '../../Cruds/CommonModals/SessionexpiryModal';
import config from '../../../config/config';
import configImages from '../../../config/configImages';

const Topbar = (props) => {
  const propTypes = {
    changeMobileSidebarVisibility: PropTypes.func.isRequired,
    changeSidebarVisibility: PropTypes.func.isRequired,
  };

  const [sessionExpiryModal, setsessionExpiryModal] = useState(false);
  const [companyName, setCompanyName] = useState(undefined);
  const [role, setRole] = useState('')

  useEffect(() => {
    getLoginData();
    let sessionexpired = localStorage.getItem('sessionexpired');
    if (sessionexpired === "true") {
      setsessionExpiryModal(true);
    }
    return () => { };
  }, []);

  const getLoginData = () => {
    const loginCredentialsString = localStorage.getItem('loginCredentials');
    const loginCredentials = JSON.parse(loginCredentialsString);
    if (loginCredentials) {
      setCompanyName(loginCredentials.companyName);
      setRole(loginCredentials.role)
    }
  };

  const { changeMobileSidebarVisibility, changeSidebarVisibility } = props;
  return (
    <div className="topbar">
      <div className="topbar__wrapper">
        <div className="topbar__left">
          <TopbarSidebarButton
            changeMobileSidebarVisibility={changeMobileSidebarVisibility}
            changeSidebarVisibility={changeSidebarVisibility}
          />
          <Link className='topbarLogo ml-3' to="/dashboard" >
            {config.displayProjectName ?
              <img src={configImages.loginImage} className='topbarProjectLogo'></img> : config.appName}
          </Link>
        </div>
        <div className="topbar__center">
          <div className="topbar__center">
            {role !== 'Super Admin' ?
              <span>{companyName}</span>
              : null}
          </div>
        </div>
        <div className="topbar__right">
          <TopbarProfile />
        </div>
        {sessionExpiryModal &&
          <SessionExpiryModal SOpen={sessionExpiryModal} />
        }
      </div>
    </div>
  );
}

export default Topbar;
