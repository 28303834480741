import React from 'react'

import { useState, useEffect, useRef } from "react";
import { Modal, ModalHeader, ModalBody, Card, CardBody, ButtonToolbar, Table, ButtonGroup, Row, Col, Badge } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'primereact/button';
import classNames from 'classnames';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, Controller } from 'react-hook-form';
import fetchMethodRequest from '../../config/service';
import showToasterMessage from '../UI/ToasterMessage/toasterMessage';
import Loader from '../App/Loader';
import DoAutoCompleteField from './Fields/DoAutoCompleteField';
import DoInputField from './Fields/DoInputField';
import config from '../../config/config';
import DoSelectField from './Fields/DoSelectField';
import apiCalls from "../../config/apiCalls"
import AddCartDistributors from '../Cruds/CommonModals/WishlistModal/AddCartDistributors';
import DoRadioButtons from './Fields/DoRadioButtons';
import { Dialog } from 'primereact/dialog';


function AddCategoryModal(props) {

    let [colored, setColored] = useState(false);
    let [header, setHeader] = useState(true);
    let [schema, setSchema] = useState({});
    const [isMedicine, setIsMedicine] = useState(false);
    const [isNonMedicine, setIsNonMedicine] = useState(false);
    const [autoDetails, setAutoDetails] = useState({ name: "productName", type: "relateAutoComplete", show: true });
    const [isCategories, setIsCategories] = useState(false);
    const [inventryType, setInventryType] = useState([]);
    const isBatteryOptions = [
        { label: "Yes", value: "Yes" },
        { label: "No", value: "No" },
    ]

    const getFormFields = () => {
        let statusTypes = [
            {
                label: "Active",
                value: "Active",
            },
            {
                label: "Inactive",
                value: "Inactive",
            },
            {
                label: "Pending",
                value: "Pending",
            },
        ];
        return [
            {
                name: "categoryName",
                type: "Text",
                placeholder: "Category Name",
                label: "Category Name",
                width: 120,
                header: "Category Name",
                id: "categoryName",
                addFormOrder: 1,
                editFormOrder: 1,
                displayinlist: "true",
                globalSearchField: "true",
                controllerId: 1001,
                fieldType: "Text",
                textAlign: "Center",
                show: true,
                field: "categoryName",
                mobile: true,
                filter: true,
                displayInSettings: true,
            },
            {
                name: "categoryType",
                type: "dropDown",
                placeholder: "Category Type",
                label: "Category Type",
                width: "130px",
                addFormOrder: 7,
                editFormOrder: 7,
                derivedValue: "categoryType=undefined",
                capitalizeTableText: false,
                sortable: false,
                isClickable: false,
                filter: false,
                actions: [],
                actionsNumber: [],
                id: "categoryType",
                displayinaddForm: "true",
                displayineditForm: "true",
                displayinlist: "true",
                isFieldRequired: "true",
                required: true,
                displayOptionsInActions: false,
                globalSearchField: "true",
                controllerId: null,
                options: [
                    { label: "Medicine", value: "Medicine", color: "primary" },
                    { label: "Non-Medicine", value: "Non-Medicine", color: "primary" },
                ],
                fieldType: "dropDown",
                dependent: [],
                show: true,
                showOrHideFields: [],
                label0: "Medicine",
                color0: "primary",
                value0: "Medicine",
                label1: "Non-Medicine",
                color1: "primary",
                value1: "Non-Medicine",
                fieldName: "categoryType",
                mobile: true,
                displayInSettings: true,
                isAddFormHidden: false,
                isEditFormHidden: false,
            },
            {
                name: "useType",
                type: "dropDown",
                placeholder: "Use Type",
                label: "Use Type",
                width: "130px",
                addFormOrder: 8,
                editFormOrder: 8,
                derivedValue: "useType=undefined",
                capitalizeTableText: false,
                sortable: false,
                isClickable: false,
                filter: false,
                actions: [],
                actionsNumber: [],
                id: "useType",
                displayinaddForm: "true",
                displayineditForm: "true",
                displayinlist: "true",
                isFieldRequired: "true",
                required: true,
                displayOptionsInActions: false,
                globalSearchField: "true",
                controllerId: null,
                options: [
                    { label: "SingleUse", value: "SingleUse", color: "primary" },
                    { label: "MultiUse", value: "MultiUse", color: "primary" },
                ],
                fieldType: "dropDown",
                dependent: [],
                show: true,
                showOrHideFields: [],
                mobile: true,
                displayInSettings: true,
                isAddFormHidden: false,
                isEditFormHidden: false,
            },

        ];
    };




    let modalClass = classNames({
        'modal-dialog--colored': colored,
        'modal-dialog--header': header,
    });

    let {
        handleSubmit,
        register,
        reset,
        setValue,
        getValues,
        formState: { errors },
        control,
    } = useForm({
        resolver: yupResolver(schema),
    });

    useEffect(() => {
        setSchema(yup.object().shape({
            productName: yup.string().required('productName  is required'),
            description: yup.string().required('description  is required'),
            category: yup.object().required('category  is required'),
            inventoryType: isMedicine && yup.string().required('inventoryType  is required'),
            volume: isMedicine && yup.string().required('volume  is required'),

        }));
        const modal = document.querySelector('div[tabindex="-1"][style*="z-index: 1050;"]');
        if (modal) {
            modal.style.zIndex = '300';
        }
        getInventryType();
    }, []);

    let closeFormModal = async () => {
        // props.reset();
        props.closeFormModal();
        // clearFormFields();
    }

    // close Categorys
    const closeCategorys = () => {
        setIsCategories(false)
    }

    const AddCategories = (e) => {
        e.preventDefault();
        setIsMedicine(false);
        setIsNonMedicine(false);
        setIsCategories(!isCategories)
        // buttonRef.current.blur();
    }

    const submit = async (values) => {
        if (values) {
            sendDataToServer(values)
        }
    }

    //Setting call
    const getInventryType = () => {
        return fetchMethodRequest("GET", apiCalls.Settings
        ).then(async (response) => {
            if (response && response.settings && response.settings[0]["inventoryTypes"]) {
                setInventryType(response.settings[0]["inventoryTypes"])
            }
        });

    }


    const sendDataToServer = (formValues) => {

        let apiUrl = isCategories ? apiCalls.categories : props.apiUrl
        let method = "POST"
        let userBody = Object.assign({}, formValues);
        const loginCredentials = localStorage.getItem("loginCredentials");
        const credentialsObject = JSON.parse(loginCredentials);
        let loginRole = credentialsObject.role
        const companyId = credentialsObject._id;
        userBody.loginRole = `${loginRole}`;
        userBody.companyId = loginRole === 'Admin' ? `${companyId}` : loginRole === 'Super Admin' ? `${props.adminCompanyId}` : null;
        if (formValues) {

            if (apiUrl === "products") {
                if (formValues.category.categoryType === "Non-Medicine") {
                    userBody.batteryExists = formValues.batteryExists === "Yes" ? true : false
                }
            }

        }

        return fetchMethodRequest(method, apiUrl, userBody)

            .then(async (response) => {
                if (response && response.respCode) {
                    isCategories ? closeCategorys() : props.closeFormModal();
                    // if (props.categoryType) {
                    await props.getDataFromServer(undefined, props.categoryType);
                    // } else {
                    //     await props.getDataFromServer();
                    // }
                    // showToasterMessage(response.respMessage, 'success');
                    //   props.reset();
                } else if (response && response.errorMessage) {
                    showToasterMessage(response.errorMessage, 'error');
                }

            }).catch((err) => {
                return err;
            });
    }

    let handleAutoCompleteData = async (value, name) => {
        if (value) {
            setIsCategories(false);
            if (name === "category") {

                if (value && value.categoryType) {
                    if (value.categoryType) {
                        if (value.categoryType == "Medicine") {
                            setIsMedicine(true);
                            setIsNonMedicine(false);

                        } else if (value.categoryType == "Non-Medicine") {
                            setIsMedicine(false);
                            setIsNonMedicine(true);
                        }
                    }
                }
            }

        }
    }


    //getModalHeader
    let getModalHeader = () => {
        let { formType } = props;
        return (
            <ModalHeader className="modal__header">
                <button className="lnr lnr-cross modal__close-btn" type="button" onClick={closeFormModal} />
                <p className="bold-text  modal__title">
                    Add Product Directory
                </p>
            </ModalHeader>
        )
    }

    const getButtons = () => {
        {/* Button */ }
        return (
            <div className='d-flex'>
                <div className='col-12 px-0'>
                    <span className='float-end'>
                        <ButtonToolbar>
                            <Button color='primary' className='btn custom-outline' type="button" outline onClick={() => closeFormModal()}>
                                {'Cancel'}
                            </Button>
                            <Button color='primary' className='btn custom-outline' outlined type="submit">
                                {"Save"}
                            </Button>
                        </ButtonToolbar>
                    </span>
                </div>
            </div>
        )
    }

    let getViewBody = () => {
        // let item = distributorsFields

        return (

            <>
                {/* Buttons */}
                <form onSubmit={handleSubmit(submit)} autoComplete={'off'}>


                    {getButtons()}


                    <div className='row form'>
                        <div className="col-md-6 ">
                            <Controller
                                name={'productName'}
                                control={control}
                                render={({ field, fieldState }) => (
                                    <DoInputField
                                        markReq={true}
                                        input={field}
                                        id={"productName"}
                                        name={field.name}
                                        label={"product Name"}
                                        field={field}
                                        type={"text"}
                                        fieldState={fieldState}
                                        errors={errors}
                                        defVal={''}
                                        placeholder={"Product Name"}
                                    />)}
                            />
                        </div>

                        <div className="col-md-6 ">
                            <Controller
                                name={'description'}
                                control={control}
                                render={({ field, fieldState }) => (
                                    <DoInputField
                                        markReq={true}
                                        input={field}
                                        id={"description"}
                                        name={field.name}
                                        label={"Description"}
                                        field={field}
                                        type={"text"}
                                        fieldState={fieldState}
                                        errors={errors}
                                        defVal={''}
                                        placeholder={"Description"}
                                    />)}
                            />
                        </div>



                        <div className="col-md-6 d-flex">
                            <Controller
                                name={'category'}
                                control={control}
                                render={({ field, fieldState }) => (
                                    <DoAutoCompleteField
                                        markReq={true}
                                        input={field}
                                        id={field.id}
                                        name={field.name}
                                        field={field}
                                        filterField={false}
                                        filterValue={false}
                                        filterType={false}
                                        multiple={false}
                                        displayCol={"col-md-10"}
                                        fieldState={fieldState}
                                        errors={errors}
                                        screen={props.type}
                                        searchApi={'categories'}
                                        searchField={'categoryName'}
                                        allow={props.allowDuplicates}
                                        filterFieldType={null}
                                        onChange={(event) => handleAutoCompleteData(event, 'category')}
                                        placeholder={'Category Name'}
                                        label={'Category'}
                                        item={autoDetails}
                                        getValues={getValues}
                                    // isDisabled={formType == "edit" ? true : false}
                                    />)}
                            />

                            {/* showDistributorData  */}

                            <div className='col-md-2' style={{ marginLeft: "1.72rem" }}>
                                <Button color="primary"
                                    size="sm"
                                    className="p-1 ml-auto  mb-0 product_btn"
                                    onClick={(e) => AddCategories(e)}
                                >
                                    <FontAwesomeIcon
                                        icon='plus'
                                        className='pl-1' size='lg'
                                        style={{ fontSize: "1.3rem", padding: "1px" }}
                                        data-toggle="tool-tip" title={("Add")}
                                        onClick={(e) => AddCategories(e)}
                                    />
                                </Button>
                            </div>
                        </div>
                        {isNonMedicine &&
                            <div className="col-md-6 mt-2">
                                <Controller
                                    name={"batteryExists"}
                                    control={control}
                                    render={({ field, fieldState }) => (
                                        <DoRadioButtons
                                            markReq={true}
                                            options={isBatteryOptions}
                                            input={field}
                                            id={field.id}
                                            name={field.name}
                                            field={field}
                                            label={"Battery Exists"}
                                            fieldState={fieldState}
                                            errors={errors}
                                            defVal={""}
                                        />)}
                                />
                            </div>}

                        {isMedicine &&
                            <div className="col-md-6 ">
                                <Controller
                                    name={'inventoryType'}
                                    control={control}
                                    render={({ field, fieldState }) => (
                                        // <DoInputField
                                        //     markReq={true}
                                        //     input={field}
                                        //     id={"inventoryType"}
                                        //     name={field.name}
                                        //     label={"InventoryType"}
                                        //     field={field}
                                        //     type={"text"}
                                        //     fieldState={fieldState}
                                        //     errors={errors}
                                        //     defVal={''}
                                        //     placeholder={"InventoryType"}
                                        // />
                                        <DoSelectField
                                            input={field}
                                            markReq={true}
                                            id={field.id}
                                            name={field.name}
                                            field={field}
                                            label={"InventoryType"}
                                            fieldState={fieldState}
                                            errors={errors}
                                            options={inventryType}
                                            optionLabel={"label"}
                                            placeholder={"InventoryType"}
                                        />


                                    )}
                                />
                            </div>
                        }
                        {isMedicine &&
                            <div className="col-md-6 ">
                                <Controller
                                    name={'volume'}
                                    control={control}
                                    render={({ field, fieldState }) => (
                                        <DoInputField
                                            markReq={true}
                                            input={field}
                                            id={"volume"}
                                            name={field.name}
                                            label={"Volume"}
                                            field={field}
                                            type={"text"}
                                            fieldState={fieldState}
                                            errors={errors}
                                            defVal={''}
                                            placeholder={"Volume"}
                                        />)}
                                />
                            </div>
                        }
                    </div>
                </form>

                {/* Categorys */}

                {isCategories ?
                    <AddCartDistributors
                        formFields={getFormFields}
                        sendDataToServer={sendDataToServer}
                        closeFormModal={closeCategorys}
                        type={'categories'}
                    />
                    : null}

            </>
        )
    }

    return (
        <div>
            {/* display Modal for Reference  */}
            {/* <div>
                <Modal
                    isOpen={props.openWishlistModal}
                    className={`modal-dialog-centered modal-dialog--primary  ${modalClass}`}
                    style={{}}
                >
                    {getModalHeader()}
                    {
                        <ModalBody className="modal__body mb-0 pt-1">
                            <Card className='pb-0 cardForListMargin'>
                                <CardBody className='tableCardBody'>
                                    {getViewBody()}
                                </CardBody>
                            </Card>
                        </ModalBody>
                    }
                </Modal>
            </div> */}
            <div>
                <Dialog
                    visible={props.openWishlistModal}
                    onHide={closeFormModal}
                    draggable={false}
                    style={{ width: '50vw' }}
                    closeOnEscape={false}
                    header=
                    {<div>
                        <p className="bold-texts modal__titles">
                            Add Product Directory
                        </p>
                    </div>
                    }
                >
                    {
                        <ModalBody className="modal__body mb-0 pt-1">
                            <Card className='pb-0 cardForListMargin'>
                                <CardBody className='tableCardBody'>
                                    {getViewBody()}
                                </CardBody>
                            </Card>
                        </ModalBody>
                    }
                </Dialog>
            </div>

        </div>

    )

}

export default AddCategoryModal
