import React, { useState } from "react";
import { Password } from 'primereact/password';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAsterisk } from "@fortawesome/free-solid-svg-icons";
import configMessages from "../../../config/configMessages";

const DoPasswordField = (props) => {
  const id = props.id ? props.id : props.name;
  const label = props.label ? props.label : props.name;
  const name = props.name;
  let field = props.field;
  const placeholder = props.placeholder ? props.placeholder : props.name;
  const [isFocused, setIsFocused] = useState(false);

  let markRequired = () => {
    return (
      <FontAwesomeIcon
        color='red'
        icon={faAsterisk}
        style={{ width: '7px', marginBottom: "5px", marginLeft: "2px" }}
      />
    );
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const capitalizeFirstLetter = (string) => {
    if (!string) return '';

    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  return (
    props && props.isShowIcon ? (
      <div className="flex flex-column">
        <div className="flex align-center"> {/* Added a wrapper div with flex class */}
          <label htmlFor={id} className="text-capitalize">{label}</label>
          {props.markReq && props.markReq === true && markRequired()}
        </div>

        <div className=" doInput d-flex align-items-center">
          {props && props.isShowIcon && (
            <span className="p-inputgroup-addon" style={{ height: '2.45rem', marginBottom: '7px' }}>
              <i className="pi pi-key" style={{ padding: '2px' }}></i>
            </span>
          )}
          <Password
            id={id}
            name={name}
            {...field}
            inputRef={field.ref}
            placeholder={placeholder}
            feedback={false}
            style={props.style}
            toggleMask
          />
        </div>
        <small className="text-danger ">
          {props.fieldState.invalid ?
            capitalizeFirstLetter(props.errors[props.name]?.message) : ''}
        </small>
      </div>
    ) : (
      <div className=" doInput flex flex-column">
        <div className="flex align-center">
          <label htmlFor={id} className="text-capitalize">{label}</label>
          {props.markReq && props.markReq === true && markRequired()}
        </div>
        <Password
          id={id}
          name={name}
          {...field}
          inputRef={field.ref}
          placeholder={placeholder}
          feedback={false}
          onFocus={handleFocus}
          onBlur={handleBlur}
          style={props.style}
          toggleMask
        />
        {/* </div> */}
        {isFocused && name === 'newPassword' && label === 'Create Password' && (
          <small style={{ color: 'black' }}>
            {configMessages.passwordHintMessage}
          </small>
        )}
        <small className="text-danger ">
          {props.fieldState.invalid ?
            capitalizeFirstLetter(props.errors[props.name]?.message) : ''}
        </small>
      </div>
    )
  );
}

export default DoPasswordField;