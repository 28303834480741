import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Bills from './components/Bills';
const bills = (props, { t }) => (
    <Container>
        <Bills  {...props} />
    </Container>
);

bills.propTypes = {
    t: PropTypes.func.isRequired,
};

export default withTranslation('common')(bills);
