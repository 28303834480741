
const configMessages = {
    invalidEmail: 'Please enter valid email address',
    emails: 'Please enter email',
    email: 'Please enter valid email address',
    userName: 'Please enter valid name',
    roomId: 'Please enter min 4 digits and maximum 10 digits valid  RoomId',
    name: 'Please enter valid name',
    fillField: 'Please fill above field',
    phoneNumber: 'please enter valid phone number',
    InvalidDate: 'Invalid Date',
    noRecords: 'No Records Found',
    toDateBeforeFromDate: 'To date must be after from Date',
    fromAndToRangeMustBeDifferent: 'From range and To range must be different',
    noOfDaysValidation: 'No of days between From range and To range must be same',
    toRangeDateValidation: 'To Range must be after From Range dates',
    shiftErrMsg: 'Please Select Location First',
    shiftAddOnErrMsg: 'Please Select Primary Shift First',
    toDoctorErrMsg: 'Please Select From User First',
    noOptionsMessage: 'No Options Available',
    passwordMatchValidation: 'Password does not match',
    toTimeFromTimeValidation: 'To Time must be after From Time ',
    passwordHintMessage: "Ensure your password includes one uppercase letter, one lowercase letter, one number and one special character",
    newPassword: "Password must contain at least 8 characters, one uppercase, one number and one special case character",
    confirmPassword: 'New Passwords and confirm password must match',
    selectPlanType: 'Cancel anytime if not satisfied"',
    StorageOption: 'Please review the suggested crash cart options, following your organization’s guidelines to customize the cart items to meet your organization’s needs. Once the crash cart has been customized per the above, you hereby agree to (1) attach a checklist to your cart and (2) review your cart each week, updating its contents, as necessary. By continuing your use of the Inmedtory app, you are acknowledging that you are an authorized user and you are now agreeing to the above terms. Furthermore, you agree to use the Inmedtory app at your own risk as the app providers take no responsibility for any software issues or user errors, including but not limited to the omission of cart items.'
};
export default configMessages;
