import React from "react";
import { Dropdown } from 'primereact/dropdown';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAsterisk } from "@fortawesome/free-solid-svg-icons";
import { useLocation } from 'react-router-dom';

const DoSelectField = (props) => {
    const id = props.id ? props.id : props.name;
    const label = props.label ? props.label : props.name;
    const optionLabel = props.optionLabel ? props.optionLabel : 'label';
    const name = props.name;
    const options = props.options;
    const placeholder = props.placeholder;
    let field = props.field;


    const location = useLocation();
    const isRegisterRoute = location.pathname.includes('register');


    let markRequired = () => {
        return (
            <FontAwesomeIcon
                color='red'
                icon={faAsterisk}
                style={{ width: '7px', marginBottom: "5px", marginLeft: "2px" }}
            />
        );
    };

    return (
        <div className="flex flex-column ">
            <div className="flex align-center">
                <label htmlFor={id} className="text-capitalize">{label}</label>
                {props.markReq && props.markReq === true && markRequired()}
            </div>
            <Dropdown
                value={field.value}
                optionLabel={optionLabel}
                placeholder={placeholder}
                name={name}
                options={options}
                focusInputRef={field.ref}
                onChange={(e) => field.onChange(e.value)}
                style={
                    isRegisterRoute
                        ? {
                            maxWidth: '300px',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                        }
                        : {}
                } />
            <small className="text-danger ">{props.fieldState.invalid ? props.errors[props.name]?.message : ''}</small>
        </div>
    )

}

export default DoSelectField;