import React, { PureComponent, useEffect, useState } from 'react';
import {
  Card, CardBody, Col,
} from 'reactstrap';


// config
import fetch from '../../../../config/service';
import apiCalls from '../../../../config/apiCalls';
import config from '../../../../config/config';
import { Link } from 'react-router-dom';


import { useForm, Controller } from 'react-hook-form';
import { Button } from 'primereact/button';
// import { Card } from 'primereact/card';
import DoInputField from '../../../Form/Fields/DoInputField';
import DoDateField from '../../../Form/Fields/DoDateField';
import DoPasswordField from '../../../Form/Fields/DoPasswordField';
import DoTextareaField from '../../../Form/Fields/DoTextareaField';
import DoFileUplaod from '../../../Form/Fields/DoFileUpload';
import showToasterMessage from '../../../UI/ToasterMessage/toasterMessage';



const Profile = (props) => {

  const profileFields =
    [
      { "name": "firstName", "type": "text", "placeholder": "Name", "label": "First Name", "width": 110, "id": "name", "displayinaddForm": "true", "displayineditForm": "true", "displayinlist": "true", "controllerName": null, "fieldType": "Link", "displayinregisterForm": "true", "disabled": false, "globalSearchField": "true", "show": true, "addFormOrder": 1, "editFormOrder": 1, "mobile": true, "displayInSettings": true },
      { "name": "lastName", "type": "text", "placeholder": "Name", "label": "last Name", "width": 110, "id": "name", "displayinaddForm": "true", "displayineditForm": "true", "displayinlist": "true", "controllerName": null, "fieldType": "Link", "displayinregisterForm": "true", "disabled": false, "globalSearchField": "true", "show": true, "addFormOrder": 1, "editFormOrder": 1, "mobile": true, "displayInSettings": true },
      { "name": "email", "type": "email", "placeholder": "Email", "label": "Email", "id": "email", "width": 150, "displayinaddForm": "true", "displayineditForm": "false", "displayinlist": "true", "controllerName": null, "displayinregisterForm": "true", "disabled": false, "show": true, "globalSearchField": "true", "addFormOrder": 2, "editFormOrder": 2, "mobile": true, "displayInSettings": true },
      { "name": "role", "type": "text", "placeholder": "Permissioned Role", "label": "Permissioned Role", "width": 110, "id": "name", "displayinaddForm": "true", "displayineditForm": "true", "displayinlist": "true", "controllerName": null, "fieldType": "Link", "displayinregisterForm": "true", "disabled": false, "globalSearchField": "true", "show": true, "addFormOrder": 1, "editFormOrder": 1, "mobile": true, "displayInSettings": true },
      { "name": "companyName", "type": "text", "placeholder": "company", "label": "Company", "width": 110, "id": "name", "displayinaddForm": "true", "displayineditForm": "true", "displayinlist": "true", "controllerName": null, "fieldType": "Link", "displayinregisterForm": "true", "disabled": false, "globalSearchField": "true", "show": true, "addFormOrder": 1, "editFormOrder": 1, "mobile": true, "displayInSettings": true },
      { "name": "status", "type": "text", "placeholder": "status", "label": "Status", "width": 110, "id": "name", "displayinaddForm": "true", "displayineditForm": "true", "displayinlist": "true", "controllerName": null, "fieldType": "Link", "displayinregisterForm": "true", "disabled": false, "globalSearchField": "true", "show": true, "addFormOrder": 1, "editFormOrder": 1, "mobile": true, "displayInSettings": true },

    ]
  const [userData, setUserData] = useState({});
  const [getLoginRole, setLoginRole] = useState()


  useEffect(() => {
    const loginCredentials = localStorage.getItem("loginCredentials");
    const credentialsObject = JSON.parse(loginCredentials);
    let loginRole = credentialsObject.role
    setLoginRole(credentialsObject.role)
    if (loginRole !== 'Super Admin') {
      getUserData();

    } else if (loginRole === 'Super Admin') {
      getEmployeeData()
    }
  }, []);



  let {
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
    control,
  } = useForm();



  const getEmployeeData = async () => {
    if (localStorage.getItem('loginCredentials')) {
      let user = JSON.parse(localStorage.getItem('loginCredentials'));
      let Url = `${apiCalls.employees}/${user._id}`;
      return fetch('GET', Url)
        .then(async (response) => {
          if (response) {
            setUserData({ ...response.details });
            user = Object.assign(user, response.details)
            await localStorage.setItem('loginCredentials', JSON.stringify(user));
            if (response.details.photo) {
              updateTopbarData()
            }
            reset(response.details);
          } else if (response && response.errorMessage) {
          }
        }).catch((err) => {
          return err;
        });
    }
    else {
      return;
    }
    // let url = `${apiCalls.employees}`;
    // return fetch('GET', url)
    //   .then(async (response) => {
    //     if (response) {
    //       setUserData({ ...response.employees });

    //     }
    //   })
  }
  // get data from server based on Id
  const getUserData = async () => {
    if (localStorage.getItem('loginCredentials')) {
      let user = JSON.parse(localStorage.getItem('loginCredentials'));
      let Url = `${apiCalls.companyUsers}/${user._id}`;
      return fetch('GET', Url)
        .then(async (response) => {
          if (response) {
            setUserData({ ...response.details });
            user = Object.assign(user, response.details)
            await localStorage.setItem('loginCredentials', JSON.stringify(user));
            if (response.details.photo) {
              updateTopbarData()
            }
            reset(response.details);
          } else if (response && response.errorMessage) {
          }
        }).catch((err) => {
          return err;
        });
    }
    else {
      return;
    }
  }
  const updateTopbarData = () => {
  }
  const getFileName = (file) => {
    updateTopbarData();
  }

  const onClickUpdate = (data) => {
    if (getLoginRole !== 'Super Admin') {
      updateProfile({ ...data });
    } else if (getLoginRole === 'Super Admin') {
      updateEmployeeProfile({ ...data })
    }
  }

  const updateProfile = (formData) => {
    if (formData) {
      delete formData.email;
      delete formData.password;
      let Url;
      if (localStorage.getItem('loginCredentials')) {
        let user = JSON.parse(localStorage.getItem('loginCredentials'));
        Url = `${apiCalls.companyUsers}/${user._id}`;
      }
      return fetch('PUT', Url, formData)
        .then(async (response) => {
          if (response && response.respCode && response.respCode === 205) {
            showToasterMessage(response.respMessage, 'success');
            getUserData();
          } else if (response && response.errorMessage) {
            showToasterMessage(response.errorMessage, 'error');
          }
        }).catch((err) => {
          return err;
        });

    } else {
      return;
    }

  }


  //update employees data 
  const updateEmployeeProfile = (formData) => {
    if (formData) {
      delete formData.email;
      delete formData.password;
      let Url;
      if (localStorage.getItem('loginCredentials')) {
        let user = JSON.parse(localStorage.getItem('loginCredentials'));
        Url = `${apiCalls.employees}/${user._id}`;
      }
      return fetch('PUT', Url, formData)
        .then(async (response) => {
          if (response && response.respCode && response.respCode === 205) {
            showToasterMessage(response.respMessage, 'success');
            getUserData();
          } else if (response && response.errorMessage) {
            showToasterMessage(response.errorMessage, 'error');
          }
        }).catch((err) => {
          return err;
        });

    } else {
      return;
    }

  }

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text">Profile</h5>
          </div>
          <form onSubmit={handleSubmit(onClickUpdate)}>

            <div className='row'>
              {profileFields && profileFields.length > 0 ? profileFields.map((item, index) => (
                <div className='col-sm-12 col-md-6 pb-2'>
                  {(item.type === 'text' || item.type === 'email' || item.type === 'url') ?
                    <Controller
                      name={item.name}
                      control={control}
                      render={({ field, fieldState }) => (
                        <DoInputField
                          input={field}
                          id={field.id}
                          name={field.name}
                          label={item.label}
                          field={field}
                          type={"text"}
                          fieldState={fieldState}
                          errors={errors}
                          placeholder={item.label}
                        />)}
                    />
                    : item.type === 'date' ?

                      <Controller
                        name={item.name}
                        control={control}
                        render={({ field, fieldState }) => (
                          <DoDateField
                            input={field}
                            id={field.id}
                            name={field.name}
                            field={field}
                            fieldState={fieldState}
                            errors={errors}
                            placeholder="date"
                          />)}
                      />
                      : item.type === 'password' ?
                        <Controller
                          name={item.name ? item.name : null}
                          control={control}
                          render={({ field, fieldState }) => (
                            <DoPasswordField
                              input={field}
                              id={item.id ? item.id : null}
                              name={field.name}
                              field={field}
                              fieldState={fieldState}
                              errors={errors}
                              placeholder={item.placeholder ? item.placeholder : null}
                            />)}
                        /> :
                        item.type === 'textarea' ?
                          <Controller
                            name={item.name ? item.name : null}
                            control={control}
                            render={({ field, fieldState }) => (
                              <DoTextareaField
                                input={field}
                                id={field.id}
                                name={field.name}
                                field={field}
                                fieldState={fieldState}
                                errors={errors}
                                placeholder={item.placeholder ? item.placeholder : null}
                              />)}
                          /> : null}



                </div>
              )) : null}
              <div className='col-sm-12 col-md-6'>
                <div className="form__form-group">
                  <span className="form__form-group-label">Profile Pic</span>
                  <div className="form__form-group-field">

                    <Controller
                      name={'photo'}
                      control={control}
                      render={({ field, fieldState }) => (
                        <DoFileUplaod
                          field={field}
                          url={"uploads?uploadWhileCreate=true&uploadPath=employee"}
                          label='Upload photo'
                          acceptType="image/*"
                          showPreview={false}
                          userClickUpload={true}
                        />)}
                    />
                  </div>

                </div>
              </div>

            </div>


            <div className='d-flex mt-3 justify-content-end'>
              <Link to={'/dashboard'}>
                <Button style={{ marginLeft: '50px' }} className='me-3 btn custom-outline' color='primary'
                >Cancel</Button>
              </Link>
              <Button type='submit' size='sm' color='primary' className='btn custom-outline' outlined label='Update' />
            </div>


          </form>
        </CardBody>
      </Card>
    </Col >
  );
}




export default Profile;